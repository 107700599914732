import './Spinner.scss'

const Spinner = ({date}) => {
  return (
      <div className={`preloader ${date ? date : ''}`}>
          <div className="preloader__spinner"></div>
      </div>
  )
}

export default Spinner