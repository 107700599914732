import {gql } from '@apollo/client';

export const CREATE_BOOKING = gql`
  mutation CreateBooking($input: BookingInput!) {
    createBooking(input: $input) {
      uuid
      status
      price
      notes
    }
  }
`;

export const CANCEL_BOOKING = gql`
  mutation CancelBooking($uuid: Uuid!) {
    cancelBooking(uuid: $uuid)
  }
`;

export const ADD_REVIEW = gql`
  mutation AddReview($reviewInput: ReviewInput!, $bookingId: Uuid!) {
    leftReview(reviewInput: $reviewInput, bookingId: $bookingId)
  }
`;