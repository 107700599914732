const defaultState = {
    companyId : null,
    companyImage: null,
    confirmationCallRequired : null,
    showAds : null,
    language : null,
    countryIsoCode : null
}

const SET_COMPANY = 'SET_COMPANY';
const RESET_COMPANY = 'RESET_COMPANY';

export const companyReducer = (state = defaultState, action) => {
    switch (action.type) {
        case SET_COMPANY:
            const updatedState = {
                ...state,
                ...action.payload,
            };
            localStorage.setItem('companyData', JSON.stringify(updatedState));
            return updatedState;

        case RESET_COMPANY:
            localStorage.removeItem('companyData');
            return defaultState;

        default:
            return state;
    }
};

export const setCompany = (payload) => ({type: SET_COMPANY, payload});
export const resetCompanyState = () => ({
    type: RESET_COMPANY,
});