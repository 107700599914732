import errorImg from "../../../img/errors/404_img.png";
import {useTranslation} from "react-i18next";

const PageNotFound = () => {

    const {t: translate} = useTranslation();
    return (
        <div className={"preloader"}>
            <div className={"d-flex flex-column flex-md-row container"}>
                <div className={"d-flex flex-column col-xl-6 mb-lg-0 mb-3 me-lg-5"}>
                    <span className={"error-type fw-bold mb-4 fs-"}>404</span>
                    <h2 className={"main_text_color mb-3"}>
                        {translate('pageNotFound')}
                    </h2>
                    <p className={"mb-0 text-secondary"}>
                        {translate('pageNotFoundInfo')}.
                    </p>
                </div>
                <div className="wrapper-error-img col-xl-6 d-flex justify-content-end">
                    <img src={errorImg} alt=""/>
                </div>
            </div>
        </div>
    )
}

export default PageNotFound;