const defaultState = {
    validate : true
}

const SET_BOOKING_ERROR = 'SET_BOOKING_VALIDATE';
const RESET_STATE = 'RESET_STATE';

export const bookingValidateReducer = (state = defaultState, action) => {
    switch (action.type) {
        case SET_BOOKING_ERROR :
            localStorage.setItem('validateData', JSON.stringify(action.payload));
            return {...state,
                ...action.payload,
            }
        case RESET_STATE :
            localStorage.setItem('validateData', JSON.stringify(defaultState));
            return defaultState;
        default : return state;
    }
}

export const setBookingValidate = (payload) => ({type: SET_BOOKING_ERROR, payload});
export const resetBookingValidate = () => ({
    type: RESET_STATE,
});