import {ApolloClient, HttpLink, InMemoryCache} from "@apollo/client";
import {POS_URL} from "../environment";


export const apolloClientPos = new ApolloClient({
    // uri: process.env.REACT_APP_BACKEND_ADDRESS + "/graphql",
    link: new HttpLink({
        uri: `${POS_URL}/graphql/`,
        // credentials: 'include',
        // fetchOptions: {
        //     mode: 'no-cors'
        // }
        headers : {
            'service-key-name' : 'booking'
        }
    }),
    cache: new InMemoryCache(),
    // fetchOptions: {
    //     mode: 'no-cors',
    // },
});


export const apolloClientPosBranch = new ApolloClient({
    // uri: process.env.REACT_APP_BACKEND_ADDRESS + "/graphql",
    link: new HttpLink({
        uri: `${POS_URL}/graphql/booking`,
        // credentials: 'include',
        // fetchOptions: {
        //     mode: 'no-cors'
        // }
        headers : {
            'service-key-name' : 'booking'
        }
    }),
    cache: new InMemoryCache(),
    // fetchOptions: {
    //     mode: 'no-cors',
    // },
});