import React, {useEffect} from 'react';
import './Advertising.scss';
import AdsTicket from "./AdsTicket";
import AdsQr from "./AdsQr";
import {arrayUtils} from '../../utils/ArrayUtils';
import {useSelector} from "react-redux";

function Advertising() {
    const adsComponents = [<AdsQr/>, <AdsTicket/>];
    const showAds = useSelector(state => state.companyReducer.showAds);

    useEffect(() => {
        if (showAds) {
            let adsbygoogle;
            // eslint-disable-next-line
            (adsbygoogle = window.adsbygoogle || []).push({});
        }
    }, [showAds]);

    return (
        <>
            {showAds && <div
                className="advertising bg-white position-sticky bottom-0 start-0 d-flex align-items-center justify-content-center">
                <div className="container px-lg-5 h-100">
                    <div className={"h-100"}>
                        <ins className="adsbygoogle d-block ad-footer"
                             data-ad-client="ca-pub-7056109210876208"
                             data-ad-slot="3927225694"
                            // data-ad-format="auto"
                            // data-full-width-responsive="true"
                        >
                            <div id="fallback_ad" className={"h-100"}>{arrayUtils(adsComponents)}</div>
                        </ins>
                    </div>

                </div>
            </div>}
        </>
    );
}

export default Advertising;
