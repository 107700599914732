import MasterItem from "./MasterItem/MasterItem";
import ContentWrapper from "../../UI/ContentWrapper/ContentWrapper";
import DefaultMaster from "./DefaultMaster/DefaultMaster";
import {useParams} from "react-router-dom";
import {EMPLOYER_LIST} from "../../apollo/Queries";
import {apolloClientPosBranch} from "../../apollo/ApolloClients";
import {useQuery} from "@apollo/client";
import defaultPhoto from './DefaultMaster/defaultMaster.svg';
import {useDispatch, useSelector} from "react-redux";
import {convertDateTimeForRequest} from "../../utils/workingWithDateTime/convertDateTimeForRequest";
import {useEffect, useState} from "react";
import {setDate} from "../../store/dateReducer";
import Spinner from "../../components/Spinner/Spinner";
import {useTranslation} from "react-i18next";
import {setBookingValidate} from "../../store/bookingValidateReducer";
import {NavigationComponent} from "../../components/NavigationComponent/NavigationComponent";

const MastersList = () => {

    const {t : translate} = useTranslation();
    const {companyId, branchId} = useParams();
    const picketDate = useSelector(state => state.dateReducer.currentDate);
    const dateIsEntered = useSelector(state => state.dateReducer.dateIsEntered);
    const currentService = useSelector(state => state.serviceReducer.serviceId);
    const isBookingValid = useSelector(state => state.bookingValidateReducer.validate);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);

    const {data: employerList} = useQuery(EMPLOYER_LIST,
        {
            client: apolloClientPosBranch,
            variables: {
                branchId: branchId,
                picketDate: dateIsEntered ? convertDateTimeForRequest(picketDate) : null,
                serviceId: currentService
            }, onCompleted: () => {
                setLoading(false);
            }
        });

    useEffect(() => {
        if (!dateIsEntered) {
            dispatch(setDate({currentDate: null, dateIsEntered: false}))
        }
        if (!isBookingValid) {
            dispatch(setBookingValidate({validate : true}))
        }
    }, [dateIsEntered, dispatch, isBookingValid]);

    return (
        <>
            <div className={"bg-white"}>
                <NavigationComponent
                    navLink={`/company/${companyId}/branch-menu-list/${branchId}`}
                    linkText={translate('backToMenu')}
                />
            </div>
            <div className="masters">
                <div className="master-items-list">
                    {(employerList && employerList.employees && employerList.employees.length !== 0) ?
                        <>
                            {employerList.employees.length > 1 && (<DefaultMaster companyId={companyId} branchId={branchId}/>)}
                            {
                                employerList.employees.map((employeeItem) => (
                                    <ContentWrapper key={employeeItem.id}>
                                        <MasterItem
                                            masterName={employeeItem.name}
                                            masterPosition={employeeItem.employeePosition.name}
                                            masterDesc={employeeItem.specialization}
                                            aboutMaster={employeeItem.about}
                                            masterNearestEntry={picketDate ? picketDate : employeeItem.closestBookingDate}
                                            masterPhoto={employeeItem.image ? employeeItem.image : defaultPhoto}
                                            closestBookingDateTimes={employeeItem.closestBookingDateTimes}
                                            employeeId={employeeItem.id}
                                            avgRating={employeeItem.avgRating}
                                            reviewCount={employeeItem.reviewCount}
                                            employeeServiceRelation={employeeItem.employeeServiceRelation}
                                            masterSurname={employeeItem.surname}
                                        />
                                    </ContentWrapper>
                                ))
                            }
                        </>
                        : <p className={"mb-0 text-secondary text-center"}>{translate('masterListPage.employeeNotFound')}.</p>
                    }
                    {loading ? <Spinner/> : null}
                </div>
            </div>
        </>
    )
}

export default MastersList