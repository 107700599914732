import ContentWrapper from "../../UI/ContentWrapper/ContentWrapper";
import './Reservation.scss'
import {useEffect, useState} from "react";
import ReservationForm from "./ReservationForm/ReservationForm";
import {useNavigate, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {NavigationComponent} from "../../components/NavigationComponent/NavigationComponent";

const Reservation = () => {

    const {t: translate} = useTranslation();
    const {companyId, branchId} = useParams();
    const [showForm, setShowForm] = useState(true);
    const currentDate = useSelector(state => state.dateReducer.currentDate);
    const [errorsList, setErrorsList] = useState({});
    const navigate = useNavigate();
    const errorsListIsEmpty = Object.keys(errorsList).length !== 0;
    const switchContentHandler = (event) => {
        event.preventDefault();
        const contactBtn = 'contactInfoBtn';
        const detailsBtn = 'bookingDetailsBtn';
        const btnId = event.target.id;
        if (event.target.tagName === 'BUTTON') {
            if (btnId === detailsBtn) {
                setShowForm(false);
            }
            if (btnId === contactBtn) {
                setShowForm(true);
            }
        }
    }

    const setErrorsListHandler = (currentErrorList) => {
        setErrorsList(currentErrorList)
    }

    useEffect(() => {
        if (!currentDate) {
            navigate(`/company/${companyId}`)
        }
    })

    const addActiveClass = (stateValue) => {
        return stateValue ? 'active' : ''
    }

    return (
        <>
            <NavigationComponent
                navLink={`/company/${companyId}/branch-menu-list/${branchId}`}
                linkText={translate('backToMenu')}
            />
            <div className="reservation-wrapper">
                <ContentWrapper>
                    <div className="btn-wrapper d-flex mb-4" onClick={switchContentHandler}>
                        <div
                            className={`${addActiveClass(showForm)}  
                             col-6 border-bottom border-2 d-flex justify-content-center currentBtn ${errorsListIsEmpty ? 'error ' : ''}`}>
                            <button
                                id="contactInfoBtn"
                                className={`btn rounded-0 w-100 fs-7 fw-bold main_text_color ${errorsListIsEmpty ? ' text-danger' : ''}`}>
                                {translate('reservationPage.contactInfo')}
                                {errorsListIsEmpty ?
                                    <i className="bi bi-exclamation-circle ms-2"></i>
                                    :
                                    null}
                            </button>
                        </div>
                        <div
                            className={addActiveClass(!showForm) + " col-6 border-bottom  border-2 d-flex justify-content-center currentBtn"}>
                            <button
                                id="bookingDetailsBtn"
                                className="btn rounded-0 w-100 fw-bold fs-7 main_text_color">
                                {translate('reservationPage.reservationInfoBtn')}
                            </button>
                        </div>
                    </div>
                    <ReservationForm showForm={showForm} setShowForm={setShowForm}
                                     setErrorsListHandler={setErrorsListHandler}
                                     errorsListIsEmpty={errorsListIsEmpty}
                    />
                </ContentWrapper>
            </div>
        </>
    )
}

export default Reservation