import './Serveice.scss'
import {useDispatch} from "react-redux";
import {setService} from "../../../store/serviceReducer";
import {useNavigate, useParams,} from 'react-router-dom';
import {useTranslation} from "react-i18next";
import timeRangeConvert from "../../../utils/workingWithDateTime/timeRangeConvert";
import priceRangeConvert from "../../../utils/priceRangeConvert";

const Service = ({description, serviceCategory, serviceId, servicePrice, workPhoto, serviceTime, currencyCode}) => {

    const {t : translate} = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {companyId, branchId} = useParams();


    const addServiceHandler = () => {

        const currentServiceInfo = {
            serviceId: serviceId,
            servicePrice: servicePrice,
            serviceName: serviceCategory,
            serviceTime: serviceTime,
            currencyCode: currencyCode,
            servicePriceRange : servicePrice,
            serviceTimeRange : serviceTime
        };

        dispatch(setService(currentServiceInfo));
        navigate(`/company/${companyId}/branch-menu-list/${branchId}`);
    }

    return (
        <div className="service">
            <div className="service-title d-flex justify-content-between align-items-center mb-2">
                <h5 className="mb-0 main_text_color">{serviceCategory}</h5>
                <span className="icon_to_block d-flex align-items-center main_text_color">
                  <i className="bi bi-clock-history me-2"></i>
                    {timeRangeConvert(serviceTime)}
              </span>
            </div>
            <div className="service_image w-100 mb-3">
                <img src={workPhoto} alt="" className="d-block w-100 img-fluid"/>
            </div>
            <p className="main_text_color fs-7 mb-3">
                {description}
            </p>
            <div className="price-and-tag d-flex justify-content-between">
                <div className="px-2 icon_to_block text-success d-flex align-items-center service-price rounded justify-content-center">
                    <i className="bi bi-tag me-2"></i>
                    <span>{priceRangeConvert(servicePrice)} {currencyCode}</span>
                </div>
                <div className="col-3">
                        <button
                            onClick={addServiceHandler}
                            className="btn btn-sm btn-outline-primary w-100 rounded-1">
                            {translate('branchPage.choice')}
                        </button>
                </div>
            </div>
        </div>
    )
}

export default Service