import './FilledWrapper.scss'

const FilledWrapper = ({children}) => {
  return (
      <div className="container">
          <div className="filled-wrapper container py-3 px-4 col-lg-8 rounded bg-white mb-3 d-flex justify-content-between align-items-center">
              {children}
          </div>
      </div>
  )
}
export default FilledWrapper