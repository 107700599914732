const selectOptionsControls = (pickedDate, currentInterval, currentTimeByTimeZone) => {

    const currentDate = new Date(pickedDate).getTime();
    const halfHourCase = new Date(new Date(currentTimeByTimeZone).setMinutes(new Date(currentTimeByTimeZone).getMinutes() + 30));
    const oneHourCase = new Date(new Date(currentTimeByTimeZone).setHours(new Date(currentTimeByTimeZone).getHours() + 1));
    const twoHourCase = new Date(new Date(currentTimeByTimeZone).setHours(new Date(currentTimeByTimeZone).getHours() + 2));
    const threeHourCase = new Date(new Date(currentTimeByTimeZone).setHours(new Date(currentTimeByTimeZone).getHours() + 3));
    const oneDayCase = new Date(currentTimeByTimeZone);
    oneDayCase.setDate(oneDayCase.getDate() + 1);
    const threeDayCase = new Date(currentTimeByTimeZone);
    threeDayCase.setDate(threeDayCase.getDate() + 3);

    switch (currentInterval) {
        case '30m': {
            return currentDate > halfHourCase.getTime();
        }
        case "1h": {
            return currentDate > oneHourCase.getTime();
        }
        case "2h": {
            return currentDate > twoHourCase.getTime();
        }
        case "3h": {
            return currentDate > threeHourCase.getTime();
        }
        case "1d": {
            return currentDate > oneDayCase.getTime();
        }
        case "3d": {
            return currentDate > threeDayCase.getTime();
        }
        default:
            return false;
    }
}


export default selectOptionsControls