import BranchMenuItem from "./BranchMenuItem/BranchMenuItem";
import personLogo from './img/personLogo.svg';
import clockLogo from './img/clockLogo.svg';
import serviceLogo from './img/serviceLogo.svg'
import {Link, useNavigate, useParams} from "react-router-dom";
import FilledMaster from "./FilledMaster/FilledMaster";
import FilledTime from "./FilledTime/FilledTime";
import FilledService from "./FilledService/FilledService";
import {useQuery} from "@apollo/client";
import {COMPANY_BRANCH_INFO} from "../../../apollo/Queries";
import {apolloClientPos} from "../../../apollo/ApolloClients";
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {setDate} from "../../../store/dateReducer";
import Spinner from "../../../components/Spinner/Spinner";
import BranchNotFound from "../../../components/ErrorComponents/BranchNotFound/BranchNotFound";
import {useTranslation} from "react-i18next";
import ValidateBooking from "./ValidateBooking/ValidateBooking";
import {setBranch} from "../../../store/branchReducer";
import {NavigationComponent} from "../../../components/NavigationComponent/NavigationComponent";

const BranchMenuList = () => {
    const {t: translate} = useTranslation();
    const currentService = useSelector(state => state.serviceReducer);
    const currentBranchInfo = useSelector(state => state.branchReducer);
    const isPicketDate = useSelector(state => state.dateReducer.dateIsEntered);
    const currentDate = useSelector(state => state.dateReducer.currentDate);
    const currentEmployee = useSelector(state => state.employeeReducer);
    const bookingValidate = useSelector(state => state.bookingValidateReducer.validate);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const isBookingValid = useSelector(state => state.bookingValidateReducer.validate);
    const [loading, setLoading] = useState(true);

    const showReservationBtn = (currentEmployee.employeeId && currentService.serviceId && isPicketDate && bookingValidate);

    const {companyId, branchId} = useParams();
    const {data: companyBranch} = useQuery(COMPANY_BRANCH_INFO(branchId), {
        client: apolloClientPos,
        onCompleted: (data) => {
            if (!currentBranchInfo.branchName) {
                dispatch(setBranch({
                    branchName: data.branch.name,
                    branchAddress: data.branch.address,
                    branchCity: data.branch.city.name,
                    timeZone: data.branch.timeZone
                }));
            }
            setLoading(false);
        }, onError: (error) => {
            console.log(error)
            setLoading(false)
        }
    });

    useEffect(() => {
        if (!isPicketDate) {
            dispatch(setDate({currentDate: null, dateIsEntered: false, timeIsPickedIn : null, pickedTime : null}))
        }

    }, [isPicketDate, dispatch])


    return (
        <>
            <div className={"bg-white"}>
                <div className={"container px-4 px-lg-0"}>
                    <NavigationComponent navLink={`/company/${companyId}`} linkText={translate('branchMenuPage.toBranches')}/>
                </div>
            </div>
            <>
                {(companyBranch && companyBranch.branch) ?
                    <div className="branch-menu px-4 px-lg-0">
                        <div className="text-decoration-none">
                            <div className="container">
                                <div className={'p-4 rounded bg-white mb-5 col-lg-8 mx-auto'}>
                                    <div className="row">
                                        <div className="col-lg-9 col-md-9 col-12 mb-4 mb-md-0">
                                            <div
                                                className="branch-name mb-1 icon_to_block d-flex justify-content-between">
                                                <h5 className="fw-bold text-dark branch-name">{companyBranch.branch.name}, {translate('cancelPage.city')}.{companyBranch.branch.city.name}</h5>
                                            </div>
                                            <div
                                                className="street_time d-flex flex-wrap icon_to_block align-items-center">
                                                <p className="text-secondary mb-0 fs-18">{companyBranch.branch.address}</p>
                                                <i className="bi bi-dot mx-2"></i>
                                                <p className="text-secondary mb-0 fs-18">
                                                    {companyBranch.branch.workSchedule
                                                        ? companyBranch.branch.workSchedule
                                                        : `${translate('branchMenuPage.workHourIsNorFound')}`}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-3">
                                            <Link
                                                to={'/company/' + companyId + '/about-branch/' + branchId + '/info'}
                                                className="btn btn-outline-primary w-100 fs-7 rounded-1 p-2">
                                                {translate('branchPage.aboutBranch')}
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {currentEmployee.employeeId ?
                            <FilledMaster/>
                            :
                            <BranchMenuItem textContent={translate('branchMenuPage.master')}
                                            currentImg={personLogo}
                                            btnText={translate('branchMenuPage.setMaster')}
                                            currentUrl={`/company/${companyId}/branch-menu-list/${branchId}/masters`}/>
                        }
                        {isPicketDate ?
                            <FilledTime errorBookingValidate={bookingValidate}/>
                            :
                            <BranchMenuItem textContent={translate('branchMenuPage.dateAndTime')}
                                            currentImg={clockLogo}
                                            btnText={translate('branchMenuPage.setDateTime')}
                                            currentUrl={`/company/${companyId}/branch-menu-list/${branchId}/set-date`}/>
                        }
                        {currentService.serviceId ?
                            <FilledService currentService={currentService}/>
                            :
                            <BranchMenuItem textContent={translate('branchMenuPage.service')}
                                            currentImg={serviceLogo}
                                            btnText={translate('branchMenuPage.setService')}
                                            currentUrl={`/company/${companyId}/branch-menu-list/${branchId}/services`}/>
                        }
                        {((currentEmployee.employeeId && currentService.serviceId && isPicketDate) || !isBookingValid) ?
                            <ValidateBooking
                                employerId={currentEmployee.employeeId}
                                serviceId={currentService.serviceId}
                                pickedDate={currentDate}
                                branchId={branchId}
                            />
                            : null
                        }
                        {showReservationBtn ?
                            <div className="container">
                                <div className={"col-lg-8 mx-auto"}>
                                    <div className={"col-lg-5 mx-auto mt-5"}>
                                        <button
                                            onClick={() => {
                                                navigate(`/company/${companyId}/branch-menu-list/${branchId}/reservation`)
                                            }}
                                            className="btn mt-3 btn-primary w-100 fw-bold fs-7  rounded">
                                            {translate('branchMenuPage.toReservation')}
                                        </button>
                                    </div>
                                </div>
                            </div> :
                            <div className="container">
                                <div className={"col-lg-8 mx-auto"}>
                                    <div className={"col-lg-5 mx-auto mt-5"}>
                                        <button disabled className="btn mt-3 text-white btn-secondary w-100 fw-bold fs-7  rounded">
                                            {translate('branchMenuPage.toReservation')}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                    : loading ? <Spinner/> : <BranchNotFound/>}
            </>
        </>

    )
}

export default BranchMenuList;