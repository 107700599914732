const defaultState = {
    serviceId: null,
    serviceName: null,
    servicePrice: null,
    serviceTime: null,
    currencyCode : null,
    servicePriceRange : null,
    serviceTimeRange : null
}

const SET_SERVICE = 'SET_SERVICE_CATEGORY';
const RESET_STATE = 'RESET_STATE';

export const serviceReducer = (state = defaultState, action) => {
    switch (action.type) {
        case SET_SERVICE :
            localStorage.setItem('serviceData', JSON.stringify(action.payload));
            return {...state,
                ...action.payload,
            }
        case RESET_STATE :
            localStorage.setItem('serviceData', JSON.stringify(defaultState));
            return defaultState;
        default : return state;
    }
}

export const setService = (payload) => ({type: SET_SERVICE, payload});
export const resetServiceState = () => ({
    type: RESET_STATE,
});