import convertDateByTimezone from "./convertDateByTimezone";

let morning = [];
let day = [];
let night = [];

const splitTimeSpans = (currentTimeSpans, timeZone) => {
    morning = [];
    day = [];
    night = [];
    currentTimeSpans?.forEach(({start, end}) => {

        const [startHour, startMinute, startSecond] = start.split(':');
        const [endHour, endMinute, endSecond] = end.split(':');
        const startDateTime = convertDateByTimezone(timeZone).setHours(startHour, startMinute, startSecond, 0);
        const endDateTime = convertDateByTimezone(timeZone).setHours(endHour, endMinute, endSecond, 0);

        if (endDateTime < startDateTime) {
            return;
        }

        const twelveDateTime = convertDateByTimezone(timeZone).setHours(12, 0, 0, 0);
        const eighteenDateTime = convertDateByTimezone(timeZone).setHours(18, 0, 0, 0);

        if (startDateTime < twelveDateTime && endDateTime <= twelveDateTime) {
            morning.push({start: start, end: end});
        } else if (startDateTime >= twelveDateTime && endDateTime <= eighteenDateTime) {
            day.push({start: start, end: end});
        } else if (startDateTime >= eighteenDateTime) {
            night.push({start: start, end: end});
        } else if (startDateTime < twelveDateTime && endDateTime >= eighteenDateTime) {
            morning.push({start: start, end: '12:00:00'});
            day.push({start: '12:15:00', end: '17:45:00'});
            night.push({start: '18:00:00', end: end});
        } else if (startDateTime < twelveDateTime && endDateTime > twelveDateTime && endDateTime <= eighteenDateTime) {
            morning.push({start: start, end: '12:00:00'});
            day.push({start: '12:15:00', end: end});
        } else if (startDateTime >= twelveDateTime && startDateTime < eighteenDateTime && endDateTime >= eighteenDateTime) {
            day.push({start: start, end: '17:45:00'});
            night.push({start: '18:00:00', end: end});
        }
    });
}
export {
    splitTimeSpans,
    morning,
    day,
    night
}