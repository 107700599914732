import img from "../../img/reviews/modal-img.png";
import ContentWrapper from "../../UI/ContentWrapper/ContentWrapper";
import {Link, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";

const ReviewAlreadyLeft = () => {
    const {t : translate} = useTranslation();
    const {companyId} = useParams();

  return (
     <>
         <div className={"bg-white"}>
             <div className={"container px-4 px-lg-0"}>
                 <Link to={`/company/${companyId}`}
                       className={"mb-5 d-block text-decoration-none main_text_color py-3 fs-18 fw-normal "}>
                     <i className="bi bi-chevron-left me-4"></i>
                     {translate('branchMenuPage.toBranches')}
                 </Link>
             </div>
         </div>
         <ContentWrapper>
             <div className='modal-body text-center px-5 mt-5 mb-5'>
                 <img className='mb-5 img-fluid' loading='lazy' src={img} alt='Review Success'/>
                 <h4 className='fw-normal'>
                     {translate('leftReviewsPage.reviewForm.tyForReview')}!
                 </h4>
                 <p className={'mb-3 text-secondary text-center rev-comment'}>
                     {translate('leftReviewsPage.reviewForm.reviewIsAlreadyCreated')}!
                 </p>
                 <Link to={`/company/${companyId}`} className={"btn btn-primary w-100 fw-bold fs-7 rounded-1"}>
                     {translate('toMainPage')}
                 </Link>
             </div>
         </ContentWrapper>
     </>
  )
}

export default ReviewAlreadyLeft