export const convertDate = (currentDate) => {
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1;
    const day = currentDate.getDate();
    const formattedDate = `${day < 10 ? '0' + day : day}.${month < 10 ? '0' + month : month}.${year}`;
    return `${formattedDate}`;
}

export const timeToShow = (currentTime) => {
    return currentTime.split(":").slice(0, 2).join(":")
}