import 'bootstrap/js/src/collapse'
import FreeTimeComponent from "../FreeTimeComponent/FreeTimeComponent";
import {accordionOpenPosition} from "../../utils/accordionOpenPosition";
import {useEffect} from "react";
import {useTranslation} from "react-i18next";

const AccordionComponentMorning = ({
                                       morningIntervals,
                                       accordionPosition,
                                       currentDate,
                                       morningIsOpen,
                                       convertedDateByTimeZone,
                                       foolInfoMasterStyles,
                                       employeeId,
                                   }) => {
    const {t: translate} = useTranslation();
    let isOpen = false;
    const morningIntervalsIsNotEmpty = morningIntervals.length !== 0;
    if (morningIntervalsIsNotEmpty) {
        isOpen = accordionOpenPosition(12, 0, 0, accordionPosition, convertedDateByTimeZone);
    }
    if (convertedDateByTimeZone < currentDate && morningIntervalsIsNotEmpty) {
        isOpen = true;
    }
    useEffect(() => {
        morningIsOpen(isOpen)
    })

    return (
        <div>
            <div className={`accordion-item ${foolInfoMasterStyles ? 'accordion-block-styles' : ''}`}>
                <h2 className="accordion-header" id="headingOne">
                    <button
                        className={`p-0 accordion-button fw-bold ${isOpen ? '' : 'collapsed'} ${foolInfoMasterStyles ? 'py-1 px-2 accordion-header-style' : ''}`}
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne" aria-expanded={isOpen} aria-controls="collapseOne">
                        {translate('dateAndTimePage.morningTime')}
                    </button>
                </h2>
                <div id="collapseOne" className={`accordion-collapse collapse ${isOpen ? 'show' : ''}`}
                     aria-labelledby="headingOne"
                     data-bs-parent="#accordionExample">
                    <div className={`accordion-body ${foolInfoMasterStyles ? 'px-4 pt-3 pb-4' : 'px-0 pt-3'}`}>
                        <FreeTimeComponent intervals={morningIntervals}
                                           closestDate={currentDate}
                                           employeeId={employeeId}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AccordionComponentMorning;