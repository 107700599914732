import '../Reviews.scss'
import {convertDate} from "../../../utils/workingWithDateTime/formatDateTime";
import fillStars from '../../../img/reviews/stars-fill.svg';
import stars from '../../../img/reviews/stars.svg';
import './MasterReviewsShort.scss'
import {Link, useParams} from "react-router-dom";
import {calcRating} from "../../../utils/calcRating";
import {useTranslation} from "react-i18next";

const MasterReviewsShort = ({reviewsList, reviewsCount}) => {

    const {companyId, branchId, masterId} = useParams();
    const {t : translate} = useTranslation();

    return (
        <>
            {reviewsList.length > 0 ?
                <div className="text-dark mt-5">

                    <h5 className="mb-4 fs-18">{translate('masterListPage.reviews')}</h5>

                    {reviewsList.map((review) => (
                        <div className='mb-2' key={review.id}>
                            <div className="d-flex align-items-center  mb-2">
                                <div className="position-relative overflow-hidden rating-container">
                                    <img className="rating-empty-ic position-absolute top-50 start-50 translate-middle"
                                         src={fillStars} width="82" height="14" alt={"rating"}/>
                                        <img
                                            className="rating-full-ic position-absolute top-50 start-50 translate-middle"
                                            src={stars} width="82" height="14" alt={"rating"}/>
                                            <div style={{left : `${calcRating(review.rating)}%`}}
                                                 className="rating-overlay bottom-0 bg-white position-absolute "></div>
                                </div>
                                <p className="mb-0 fs-7 ms-2 line-height-14 text-orange">{review.rating}</p>
                            </div>
                            <div className='d-flex fs-6'>
                                <p className='text-capitalize mb-0'>{review.name}</p>
                                <span className='mx-3'>•</span>
                                <p className='text-info mb-0'>{convertDate(new Date(review.createdAt))}</p>
                            </div>
                            {review.text && (<p>{review.text}</p>)}
                        </div>
                        ))}

                    {reviewsCount > 3 ?
                        <Link to={`/company/${companyId}/branch-menu-list/${branchId}/masters/${masterId}/reviews`}
                              className={"w-100 btn btn-outline-orange rounded-1 fw-normal"}>
                            {translate('masterPage.allReviews')}
                        </Link> : ''}

                </div> :
                <p className={"mb-0 text-secondary text-center mt-2"}>{translate('masterPage.noReviews')}</p>}
        </>
    )
}

export default MasterReviewsShort