import './Header.scss'
import {useQuery} from "@apollo/client";
import {COMPANY} from "../../apollo/Queries";
import {apolloClientPos} from "../../apollo/ApolloClients";
import {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {setCompany} from "../../store/companyReducer";

const Header = () => {
    let companyId = window.location.pathname.split('/')[2];
    const {data: companyData} = useQuery(COMPANY(companyId), {client: apolloClientPos,
        onCompleted : (data) => {
            const storedCompanyData = JSON.parse(localStorage.getItem('companyData'));
            const companyInfo = {
                companyId,
                companyImage : data.company?.image,
                confirmationCallRequired : data.company?.confirmationCallRequired,
                showAds : data.company?.showAds,
                language : data.company?.language?.isoCode,
                countryIsoCode : data.company?.country?.isoCode
            }

            if (companyId === storedCompanyData?.companyId) {
                companyInfo.language = storedCompanyData.language
            }

            dispatch(setCompany(companyInfo));
        }});

    const dispatch = useDispatch();
    const {i18n} = useTranslation();
    useEffect(() => {
        if (companyData && companyData?.company) {
            const companyLanguage = (companyData?.company?.language?.isoCode).toLowerCase();
            const storedCompanyData = JSON.parse(localStorage.getItem('companyData'));

            const targetLanguage = (companyId !== storedCompanyData?.companyId)
                ? companyLanguage
                : storedCompanyData?.language;


            if (i18n.language !== targetLanguage) {
                i18n.changeLanguage(targetLanguage);
            }
        }

    },[companyData, companyId, dispatch, i18n])
   
    return (
        <>
            {(companyData && companyData.company) ?
                <div className="header-wrapper">
                    <header
                        className="main_bg px-4 py-4 position-relative d-flex justify-content-center align-items-center">
                        <a href={'/company/' + companyId}
                              className="text-center text-light mb-0 custom-header-size fw-bold text-decoration-none">{companyData.company.name}</a>
                    </header>
                </div>
                : ''}
        </>
    )
}

export default Header;