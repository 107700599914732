import 'bootstrap/js/src/collapse'
import FreeTimeComponent from "../FreeTimeComponent/FreeTimeComponent";
import {accordionOpenPosition} from "../../utils/accordionOpenPosition";
import {useEffect} from "react";
import {useTranslation} from "react-i18next";

const AccordionComponentDay = ({
                                   dayIntervals,
                                   morningIsOpen,
                                   currentDate,
                                   accordionPosition,
                                   dayIsOpen,
                                   convertedDateByTimeZone,
                                   foolInfoMasterStyles,
                                   employeeId,
                               }) => {
    const {t: translate} = useTranslation();
    let isOpen = false;
    const daysIntervalIsNotEmpty = dayIntervals.length !== 0;
    if (daysIntervalIsNotEmpty && !morningIsOpen) {
        isOpen = accordionOpenPosition(11, 45, 1, accordionPosition, convertedDateByTimeZone);
    }
    if (convertedDateByTimeZone < currentDate && daysIntervalIsNotEmpty) {
        isOpen = morningIsOpen ? false : daysIntervalIsNotEmpty;
    }
    useEffect(() => {
        dayIsOpen(isOpen);
    })
    return (
        <div>
            <div className={`accordion-item ${foolInfoMasterStyles ? 'accordion-block-styles' : ''}`}>
                <h2 className="accordion-header" id="headingTwo">
                    <button
                        className={`p-0 accordion-button fw-bold ${isOpen ? '' : 'collapsed'} ${foolInfoMasterStyles ? 'py-1 px-2 accordion-header-style' : ''}`}
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo" aria-expanded={isOpen} aria-controls="collapseTwo">
                        {translate('dateAndTimePage.dayTime')}
                    </button>
                </h2>
                <div id="collapseTwo" className={`accordion-collapse collapse ${isOpen ? 'show' : ''}`}
                     aria-labelledby="headingTwo"
                     data-bs-parent="#accordionExample">
                    <div className={`accordion-body ${foolInfoMasterStyles ? 'px-4 pt-3 pb-4' : 'px-0 pt-3'}`}>
                        <FreeTimeComponent intervals={dayIntervals} closestDate={currentDate} employeeId={employeeId}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AccordionComponentDay;