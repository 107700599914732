import FilledWrapper from "../../../../UI/FilledWrapper/FilledWrapper";
import CurrentMaster from "./CurrentMaster/CurrentMaster";
import {useDispatch} from "react-redux";
import {setEmployee} from "../../../../store/employeeReducer";

const FilledMaster = () => {
    const dispatch = useDispatch();
    const removeEmployeeHandler = () => {
      const clearEmployee = {
          employeeId: null,
          employeeName : null,
          employeePosition: null,
          employeePhoto : null,
          employeeSurname : null
      }
      dispatch(setEmployee(clearEmployee));
    }

    return (
        <FilledWrapper>
            <CurrentMaster/>
            <button
                onClick={removeEmployeeHandler}
                className="btn icon_to_block px-0 py-0 ms-2 fs-4">
                <i className="bi bi-x-circle text-gray"></i>
            </button>
        </FilledWrapper>
    )
}

export default FilledMaster