import ContentWrapper from "../../../UI/ContentWrapper/ContentWrapper";
import defaultPhoto from './defaultMaster.svg';
import MasterFreeTime from "../MasterItem/MasterFreeTime/MasterFreeTime";
import { useQuery } from "@apollo/client";
import { BOOKING_DATES, BOOKING_TIMES } from "../../../apollo/Queries";
import ConvertDateForRequest from "../../../utils/workingWithDateTime/ConvertDateFoRequest";
import { apolloClientPosBranch } from "../../../apollo/ApolloClients";
import { useDispatch, useSelector } from "react-redux";
import { setEmployee } from "../../../store/employeeReducer";
import { useNavigate } from "react-router-dom";
import DateConvertToShow from "../../../utils/workingWithDateTime/DateConvertToShow";
import { useState } from "react";
import Spinner from "../../../components/Spinner/Spinner";
import { useTranslation } from "react-i18next";
import { setDate } from "../../../store/dateReducer";

const DefaultMaster = ({ companyId, branchId }) => {
    const { t: translate, i18n } = useTranslation();
    const currentLanguage = i18n.language;
    const currentDate = useSelector((state) => state.dateReducer.currentDate);
    const dateIsEntered = useSelector((state) => state.dateReducer.dateIsEntered);
    const timeIsPickedIn = useSelector((state) => state.dateReducer.timeIsPickedIn);
    const pickedTime = useSelector((state) => state.dateReducer.pickedTime);
    const currentService = useSelector((state) => state.serviceReducer);
    const [loading, setLoading] = useState(true);
    const [defaultMasterAvailable, setDefaultMasterAvailable] = useState(false);
    const defaultEmployeeId = '-1';
    const [dateToSet, setLocalDate] = useState(); // Change the local state variable name to setLocalDate
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { data: bookingData } = useQuery(BOOKING_DATES(branchId, defaultEmployeeId, currentService.serviceId), {
        client: apolloClientPosBranch,
        onCompleted: (data) => {
            if (data.bookingDates[0]?.start) {
                setLocalDate(currentDate ? currentDate : data.bookingDates[0].start);
                setLoading(false);
            }
        },
    });

    const { data: bookingTimesData } = useQuery(
        BOOKING_TIMES(ConvertDateForRequest(new Date(dateToSet)), branchId, defaultEmployeeId, currentService.serviceId), {
            client: apolloClientPosBranch,
            onCompleted: (data) => {
                const endTimeForDefaultMaster = data.bookingTimes[0].end;
                const [hours, minutes, seconds] = endTimeForDefaultMaster.split(':').map(Number);
                const dateTimeEnd = new Date(dateToSet);
                dateTimeEnd.setHours(hours);
                dateTimeEnd.setMinutes(minutes);
                dateTimeEnd.setSeconds(seconds);
                setDefaultMasterAvailable(new Date(dateToSet) <= dateTimeEnd);
                setLoading(false);
            },
            onError: () => {
                setLoading(false);
            },
        });

    const pickEmployeeHandler = () => {
        const newEmployee = {
            employeeId: defaultEmployeeId,
            employeeName: `${translate('masterListPage.defaultMaster')}`,
            employeePhoto: defaultPhoto,
            employeePosition: null,
        };
        if (timeIsPickedIn === defaultEmployeeId) {
            const dateToUpdate = {
                currentDate: pickedTime,
                dateIsEntered: true,
            };
            dispatch(setDate(dateToUpdate));
        }

        dispatch(setEmployee(newEmployee));
        navigate(`/company/${companyId}/branch-menu-list/${branchId}`);
    };


    return (
        <>
            {(bookingTimesData && bookingTimesData.bookingTimes && bookingData.bookingDates[0].start && defaultMasterAvailable) ?
                <ContentWrapper>
                    <div className={'d-flex justify-content-between align-items-start'}>
                        <div className="d-flex align-items-center mb-3">
                            <div className={"master-photo__wrapper rounded-circle me-4"}>
                                <img src={defaultPhoto} alt="" className="rounded-5"/>
                            </div>
                            <div className={'default-master__info'}>
                                <p className="mb-0 text-secondary fs-7">{translate('branchMenuPage.master')}</p>
                                <p className="mb-0 fw-bold main_text_color">{translate('masterListPage.defaultMaster')}</p>
                            </div>
                        </div>
                            <button
                                onClick={pickEmployeeHandler}
                                className="btn btn-primary fw-bold fs-7 px-4 rounded d-none d-md-block">
                                {timeIsPickedIn === defaultEmployeeId && !dateIsEntered ?
                                    translate('masterListPage.setSpecialistAndDateTime')
                                    :
                                    translate('masterListPage.setMaster')}
                            </button>
                    </div>
                    <button
                        onClick={pickEmployeeHandler}
                        className="btn btn-primary fw-bold fs-7 w-100 mb-4 rounded d-block d-md-none">
                        {timeIsPickedIn === defaultEmployeeId && !dateIsEntered ?
                            translate('masterListPage.setSpecialistAndDateTime')
                            :
                            translate('masterListPage.setMaster')}
                    </button>
                    {!dateIsEntered &&
                        <MasterFreeTime
                            employeeId={defaultEmployeeId}
                            masterDate={`${translate('masterListPage.closesTime')} ${DateConvertToShow(new Date(dateToSet), currentLanguage)}`}
                            closestBookingDateTimes={bookingTimesData.bookingTimes}
                            closestDate={new Date(dateToSet)}/>}
                </ContentWrapper> :
                loading ? <Spinner/> : null}
        </>
    )
}

export default DefaultMaster