import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {setCompany} from "../../store/companyReducer";

const languages = ['EN', 'UA', 'CS'];

export const LanguageSelectComponent = () => {

    const currentLanguage = useSelector(state => state.companyReducer.language)?.toUpperCase();
    const dispatch = useDispatch();
    const {i18n} = useTranslation();

    useEffect(() => {

    }, []);

    const onLanguageChangeHandler = (event) => {
        const pickedLanguage = event.target.dataset.value;
        dispatch(setCompany({language: pickedLanguage}));
        i18n.changeLanguage(pickedLanguage);
    };

    const renderLanguageOption = (lang, index) => {

        return <li className={`dropdown-item ${currentLanguage === lang && 'active'}`} key={index} data-value={lang.toLowerCase()}
                   onClick={onLanguageChangeHandler}>{lang}</li>;

    };

    return (
        <div className='d-flex'>
            <div className="nav-item dropdown ms-xl-3 ms-1 my-auto">
                <button className="btn py-0 px-1 fw-normal dropdown-toggle text-dark d-flex align-items-center"
                        id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                    <i className="bi bi-globe"></i>
                    <p className="mb-0 ms-2">
                        {currentLanguage}
                    </p>
                </button>
                <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                    {languages.map(renderLanguageOption)}
                </ul>
            </div>
        </div>
    );
};