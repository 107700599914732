import FilledWrapper from "../../../../UI/FilledWrapper/FilledWrapper";
import CurrentService from "./СurrentService/CurrentService";
import {useDispatch} from "react-redux";
import {setService} from "../../../../store/serviceReducer";

const FilledService = ({currentService}) => {
    const dispatch = useDispatch();

    const removeServiceHandler = () => {
        const clearServiceInfo = {
            serviceId: null,
            servicePrice: null,
            serviceName: null,
            serviceTime: null,
            currencyCode: null
        };
        dispatch(setService(clearServiceInfo));
    }

  return (
      <FilledWrapper>
         <CurrentService
             serviceName={currentService.serviceName}
             servicePrice={currentService.servicePrice}
             serviceTime={currentService.serviceTime}
             currencyCode={currentService.currencyCode}
         />
          <button
              onClick={removeServiceHandler}
              className="btn icon_to_block px-0 py-0 ms-2 fs-4">
              <i className="bi bi-x-circle text-gray"></i>
          </button>
      </FilledWrapper>
  )
}

export default FilledService