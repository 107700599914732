import ContentWrapper from "../../../UI/ContentWrapper/ContentWrapper";
import ReservationDetailsItem from "./ReservetionDetailsItem/ReservationDetailsItem";
import {Link, useParams} from "react-router-dom";
import {convertDate, timeToShow} from "../../../utils/workingWithDateTime/formatDateTime";
import {useQuery} from "@apollo/client";
import {GET_BOOKING} from "../../../apollo/Queries";
import {apolloClientPosBranch} from "../../../apollo/ApolloClients";
import {useState} from "react";
import Spinner from "../../../components/Spinner/Spinner";
import {useTranslation} from "react-i18next";

const ConfirmedInfo = () => {

    const {t: translate} = useTranslation();
    const notificationPath = 'reservationPage.reservationForm'
    const {companyId, branchId, uuid} = useParams();
    const [, setLoading] = useState(true);

    const {data: bookingData} = useQuery(GET_BOOKING,
        {
            client: apolloClientPosBranch, variables: {uuid: uuid},
            onCompleted: () => {
                setLoading(false);
            },
            onError : (error) => {
                console.log(error)
            }
        });

    const createNewBookingHandler = () => {
        window.location = `/company/${companyId}`
    }

    const notificationToShow = (currentDate, currentTime, notificationTime) => {
        if (notificationTime) {
            const notificationDate = new Date(notificationTime);
            const currentDateTime = new Date(`${currentDate}T${currentTime}`);

            const timeDiff = currentDateTime.getTime() - notificationDate.getTime();
            const hoursDiff = Math.floor(timeDiff / (1000 * 60 * 60));
            const daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));

            if ((timeDiff / (1000 * 60)) === 30) {
                return `${translate(`${notificationPath}.notifications.halfHour`)}`;
            } else if (hoursDiff === 1) {
                return `${translate(`${notificationPath}.notifications.oneHour`)}`;
            } else if (hoursDiff === 2) {
                return `${translate(`${notificationPath}.notifications.twoHour`)}`;
            } else if (hoursDiff === 3) {
                return `${translate(`${notificationPath}.notifications.threeHour`)}`;
            } else if (daysDiff === 1) {
                return `${translate(`${notificationPath}.notifications.oneDay`)}`;
            } else if (daysDiff === 3) {
                return `${translate(`${notificationPath}.notifications.threeDays`)}`;
            }
        } else {
            return `${translate(`${notificationPath}.notificationOff`)}`;
        }
    };


    return (
        <>
            {(bookingData && bookingData.booking && bookingData.booking.branch) ?
                <div className="confirmed-info">
                    <ContentWrapper>
                        <h5 className="fw-bold mb-4 fs-6 main_text_color">
                            {translate("confirmedInfo.reservationDetails")}
                        </h5>
                        <div className="reservation-details-list">
                            <ReservationDetailsItem title={translate('confirmedInfo.branch')}
                                                    desc={`${bookingData.booking.branch.name}, м.${bookingData.booking.branch.city?.name}`}
                                                    addressRef='' marginClass="mb-3"/>
                            <ReservationDetailsItem title={translate('confirmedInfo.address')}
                                                    desc={bookingData.booking.branch?.address}
                                                    addressRef='/'
                                                    marginClass="mb-3"/>
                            <ReservationDetailsItem title={translate('confirmedInfo.master')}
                                                    desc={`${bookingData.booking.employee?.name} ${bookingData.booking.employee?.surname}`}
                                                    addressRef='' marginClass="mb-3"/>
                            <ReservationDetailsItem title={translate('confirmedInfo.dateAndTime')}
                                                    desc={`${timeToShow(bookingData?.booking?.time)}, ${convertDate(new Date(bookingData?.booking?.date))}`}
                                                    addressRef=''
                                                    marginClass="mb-3"/>
                            <ReservationDetailsItem title={translate('reservationPage.reservationForm.notification')}
                                                    desc={notificationToShow(bookingData?.booking?.date,
                                                        bookingData?.booking?.time,
                                                        bookingData?.booking.notificationTime)} addressRef=''
                                                    marginClass="mb-3"/>
                            <ReservationDetailsItem title={translate('confirmedInfo.service')}
                                                    desc={bookingData.booking.service?.name}
                                                    addressRef=''
                                                    marginClass="mb-3"/>
                            <ReservationDetailsItem title={translate('confirmedInfo.price')}
                                                    desc={`${bookingData.booking?.price} ${bookingData.booking.branch.currencyCode}`}
                                                    addressRef=''
                                                    marginClass=""/>
                        </div>
                    </ContentWrapper>
                    <div className={"container"}>
                        <div className={"col-lg-8 mx-auto"}>
                            <div className={"row"}>
                                <div className={"col-lg-6 mb-3"}>
                                    <button onClick={createNewBookingHandler}
                                            className={"btn btn-primary w-100 fw-bold fs-7 rounded-1"}>
                                        {translate('confirmedInfo.createNewBooking')}
                                    </button>
                                </div>
                                {(bookingData.booking.status === 'Approve client' ||
                                    bookingData.booking.status === 'Waiting for client' ||
                                    bookingData.booking.status === 'Client arrived') ?
                                    <div className={"col-lg-6 mb-3"}>
                                        <div className={"col-lg-6 w-100"}>
                                            <Link
                                                to={`/company/${companyId}/branch-menu-list/${branchId}/reservation/cancel/${uuid}`}>
                                                <button
                                                    className={"btn btn-outline-primary w-100 fs-7 rounded"}>
                                                    {translate('confirmedInfo.cancelBooking')}
                                                </button>
                                            </Link>
                                        </div>
                                    </div> : ''}
                            </div>
                        </div>
                    </div>
                </div>
                : <Spinner/>
            }
        </>
    )
}

export default ConfirmedInfo