import {createStore, combineReducers} from "redux";
import {employeeReducer, setEmployee} from "./employeeReducer";
import {serviceReducer, setService} from "./serviceReducer";
import {dateReducer, setDate} from "./dateReducer";
import {composeWithDevTools} from "redux-devtools-extension";
import {branchReducer, setBranch} from "./branchReducer";
import {formReducer} from "./formReducer";
import {checkDate, retrieveCachedData} from "./localStorageUtils";
import {bookingValidateReducer, setBookingValidate} from "./bookingValidateReducer";
import {companyReducer, setCompany} from "./companyReducer";

const rootReducer = combineReducers({
    employeeReducer,
    serviceReducer,
    dateReducer,
    branchReducer,
    formReducer,
    bookingValidateReducer,
    companyReducer
})


export const store = createStore(rootReducer, composeWithDevTools());

const reducerActions = {
    branchData : setBranch,
};

if (checkDate()) {
    reducerActions.dateData = setDate;
    reducerActions.employeeData = setEmployee;
    reducerActions.serviceData = setService;
    reducerActions.companyData = setCompany;
}

reducerActions.validateData = setBookingValidate;

retrieveCachedData(store, reducerActions)