import defaultPhoto from '../../DefaultMaster/defaultMaster.svg';
import fillStars from "../../../../img/reviews/stars-fill.svg";
import stars from "../../../../img/reviews/stars.svg";
import {Link, useNavigate, useParams} from "react-router-dom";
import {calcRating} from "../../../../utils/calcRating";
import {useTranslation} from "react-i18next";
import PickMasterComponent from "../../../../components/PickMasterComponent/PickMasterComponent";
import {useDispatch, useSelector} from "react-redux";
import {setDate} from "../../../../store/dateReducer";

const MainMasterInfo = ({
                            name,
                            position,
                            photo,
                            textColor,
                            rating,
                            reviewCount,
                            employeeId,
                            surname,
                            dontNeedBtnAbout,
                            employeeServiceRelation
                        }) => {
    const {t: translate} = useTranslation();
    const {companyId, branchId} = useParams();
    const defaultEmployeeId = '-1';
    const dateStateInfo = useSelector(state => state.dateReducer);
    const navigate = useNavigate();
    const dispatch = useDispatch();


    const clearPickedTimeHandler = () => {
        if (!dateStateInfo.dateIsEntered) {
            const updateDateState = {
                timeIsPickedIn : null,
                pickedTime : null
            }
            dispatch(setDate(updateDateState));
        }
        navigate(`/company/${companyId}/branch-menu-list/${branchId}/masters/${employeeId}`)
    }

    return (
        <>
            <div className="master-item__info d-flex justify-content-between align-items-start">
                <div className={'d-flex align-items-center'}>
                    <div className="master-photo__wrapper rounded-circle me-4">
                        <img className="d-block" src={photo ? photo : defaultPhoto} alt="MasterPhoto"/>
                    </div>
                    <div className="master-item__name-position">
                        <div className='d-flex align-items-start flex-column flex-row'>
                            <div className="info-wrapper">
                                {employeeId !== defaultEmployeeId ?
                                    <Link
                                        to={`/company/${companyId}/branch-menu-list/${branchId}/masters/${employeeId}`}
                                        className={"text-decoration-none d-flex align-items-center"}>
                                        <p className="fw-bold main_text_color mb-0">{name} {surname}</p>
                                        {(rating && rating !== 0) ?
                                            <span className='text-dark mx-3 fs-6'>•</span> : null}
                                    </Link>
                                    : <p className="fw-bold main_text_color mb-0">{name}</p>
                                }
                            </div>
                            {(rating && rating !== 0) ?
                                <>
                                    <div className="d-flex align-items-center mt-lg-1 mb-lg-1 z-0">
                                        <div className="position-relative overflow-hidden rating-container">
                                            <img
                                                className="rating-empty-ic position-absolute top-50 start-50 translate-middle"
                                                src={fillStars} width="82" height="14" alt="rating"
                                            />
                                            <img
                                                className="rating-full-ic position-absolute top-50 start-50 translate-middle"
                                                src={stars} width="82" height="14" alt="rating"
                                            />
                                            <div style={{left: `${calcRating(rating)}%`}}
                                                 className="rating-overlay bottom-0 bg-white position-absolute "></div>
                                        </div>
                                        <p className="mb-0 fs-7 text-orange ms-2 line-height-14">{rating}</p>
                                        {reviewCount && (
                                            <Link
                                                to={`/company/${companyId}/branch-menu-list/${branchId}/masters/${employeeId}/reviews`}
                                                className="fs-7 main_text_color  ms-2 line-height-14">
                                                ({reviewCount} {translate('masterListPage.reviews')})
                                            </Link>
                                        )}
                                    </div>
                                </>
                                : null
                            }
                        </div>
                        <p className={textColor + " mb-0 fs-6"}>{position}</p>
                    </div>
                </div>
                <div className={`d-flex d-none d-md-block`}>
                    {!dontNeedBtnAbout && (
                        <button onClick={clearPickedTimeHandler}
                              className="btn btn-outline-primary px-3 me-2 fs-7 rounded">
                            {translate('masterListPage.aboutMaster')}
                        </button>)}
                    <PickMasterComponent
                        masterName={name}
                        employeeId={employeeId}
                        masterPosition={position}
                        masterPhoto={photo}
                        masterSurname={surname}
                        employeeServiceRelation={employeeServiceRelation}
                        fullSizeBtn={'px-3'}/>
                </div>
            </div>
        </>
    );
};

export default MainMasterInfo;
