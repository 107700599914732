const ContentWrapper = ({children}) => {
  return (
<div className={"container"}>
      <div className="p-4 rounded bg-white mb-4 col-lg-8 mx-auto">
          {children}
      </div>
</div>
  )
}

export default ContentWrapper