import React from "react";
import logoQr from "../../img/logo/logo-qr.svg";


const AdsQr = () => {
    return (
        <div className="qr-ads px-lg-4 px-3 h-100">
            <div className="d-flex align-items-center justify-content-between h-100 w-100">
                <div className="d-lg-flex col-7">
                    <div className="me-4 d-flex align-items-center">
                        <img loading='lazy' className="ads-logo" src={logoQr} alt="Me-QR Logo"/>
                    </div>
                    <div className="pe-2 pe-lg-0">
                        <p className="mb-0 text-dark d-lg-block d-none"><b>Create & Customize Your Dynamic QR Code for <span className="text-qr">FREE</span></b> </p>
                        <p className="mb-0 text-dark d-lg-none fs-7"><b>Create & Customize Your Dynamic QR Code for <span className="text-qr">FREE</span></b> </p>
                        <p className="mb-0 fs-7 text-info d-lg-block d-none">Easily generate, manage and statistically track your QR codes</p>
                    </div>
                </div>
                <div className="d-flex col-5 col-lg-3">
                    <a target="_blank" rel="noreferrer"  href="https://me-qr.com/qr-code-generator" className="btn btn-qr text-white w-100 rounded-1">Generate QR Now</a>
                </div>
            </div>
        </div>
    )
}

export default AdsQr;