import {useTranslation} from "react-i18next";

const CurrentBranch = ({photo, name, address}) => {
    const {t: translate} = useTranslation();

    return (
        <div>
            <div className="master-item__info d-flex align-items-center ">
                <div className="master-photo__wrapper rounded-circle me-4">
                    <img className="d-block" src={photo} alt="MasterPhoto"/>
                </div>
                <div className="master-item__name-position">
                    <p className="text-secondary fs-18 mb-0">{translate('confirmedInfo.branch')}</p>
                    <h5 className="fw-bold branch-name main_text_color mb-1">{name}</h5>
                    <p className="text-secondary mb-0 fs-18">{address}</p>
                </div>
            </div>
        </div>
    )
}

export default CurrentBranch