import '../Reviews.scss'
import {Link, useParams} from "react-router-dom";
import fillStars from "../../../img/reviews/stars-fill.svg";
import stars from "../../../img/reviews/stars.svg";
import {calcRating} from "../../../utils/calcRating";
import {useState} from "react";
import {useQuery} from "@apollo/client";
import {GET_REVIEWS} from "../../../apollo/Queries";
import Spinner from "../../../components/Spinner/Spinner";
import {convertDate} from "../../../utils/workingWithDateTime/formatDateTime";
import ReviewsPaginationComponent from "../../../components/ReviewsPaginationComponent/ReviewsPaginationComponent";
import {useTranslation} from "react-i18next";
import {NavigationComponent} from "../../../components/NavigationComponent/NavigationComponent";


const MasterReviews = () => {
    const {t : translate} = useTranslation();
    const {companyId, branchId, masterId} = useParams();
    const [, setLoading] = useState(true);
    const [reviewsList, setReviewsList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(20);
    const [totalItems, setTotalItems] = useState(0);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const {data: employeeReviews} = useQuery(GET_REVIEWS, {
        variables: {
            employeeId: masterId,
            sortField: 'CREATED_AT',
            order: 'DESC',
            page: currentPage,
            itemsPerPage: itemsPerPage,
        },
        onCompleted: (data) => {
            setReviewsList(data.reviews.reviews);
            setTotalItems(data.reviews.reviewCount);
            setLoading(false)
        },
        onError: (error) => {
            console.log(error)
        }
    })

    const changeFilterHandler = (event) => {
        const value = event.target.value;
        switch (value) {
            case '1' :
                const sortFromNewToOldReviews = [...reviewsList].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
                setReviewsList(sortFromNewToOldReviews);
                break
            case '2' :
                const sortFromOldToNewReviews = [...reviewsList].sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
                setReviewsList(sortFromOldToNewReviews);
                break
            case '3' :
                const sortByBiggestRating = [...reviewsList].sort((a, b) => b.rating - a.rating);
                setReviewsList(sortByBiggestRating);
                break
            case '4' :
                const sortBySmallestRating = [...reviewsList].sort((a, b) => a.rating - b.rating);
                setReviewsList(sortBySmallestRating);
                break
            default :
                return
        }
    }


    return (
        <>
            {(employeeReviews && employeeReviews.reviews) ?
                <>
                    <NavigationComponent
                        navLink={`/company/${companyId}/branch-menu-list/${branchId}/masters/${masterId}`}
                        linkText={translate('masterListPage.aboutMaster')}
                    />
                    <div className="container text-dark mb-5">
                        <div className='col-lg-9 mx-auto row'>
                            <div className='bg-white rounded p-4'>
                                <h5 className="mb-4">
                                    {translate('reviewsPage.reviewsAbout')} {employeeReviews.reviews.employee.name} {employeeReviews.reviews.employee.surname} ({employeeReviews.reviews.employee.employeePosition.name})
                                </h5>
                                <div className="form-floating mb-4">
                                    <select
                                        onChange={changeFilterHandler}
                                        className="form-select" id="floatingSelect"
                                        aria-label="Floating label select example">
                                        <option defaultValue value="1">
                                            {translate('reviewsPage.selectChoice.sortByNew')}
                                        </option>
                                        <option value="2">
                                            {translate('reviewsPage.selectChoice.sortByOld')}
                                        </option>
                                        <option value="3">
                                            {translate('reviewsPage.selectChoice.sortByGod')}
                                        </option>
                                        <option value="4">
                                            {translate('reviewsPage.selectChoice.sortByBad')}
                                        </option>
                                    </select>
                                    <label htmlFor="floatingSelect">
                                        {translate('reviewsPage.selectChoice.selectLabel')}
                                    </label>
                                </div>
                                {reviewsList.map((reviewsItem) => (
                                    <div className='mb-3' key={reviewsItem.id}>
                                        <div className="d-flex align-items-center  mb-3">
                                            <div className="position-relative overflow-hidden rating-container">
                                                <img
                                                    className="rating-empty-ic position-absolute top-50 start-50 translate-middle"
                                                    src={fillStars} width="82" height="14" alt={"rating"}/>
                                                <img
                                                    className="rating-full-ic position-absolute top-50 start-50 translate-middle"
                                                    src={stars} width="82" height="14" alt={"rating"}/>
                                                <div style={{left: `${calcRating(reviewsItem.rating)}%`}}
                                                     className="rating-overlay bottom-0 bg-white position-absolute "></div>
                                            </div>
                                            <p className="mb-0 ms-2 fs-7 line-height-14 text-orange">{reviewsItem.rating}</p>
                                        </div>
                                        <div className='d-flex fs-6'>
                                            <p className='main_text_color mb-0'>{reviewsItem.name}</p>
                                            <span className='mx-3'>•</span>
                                            <p className='text-info mb-0'>{convertDate(new Date(reviewsItem.createdAt))}</p>
                                        </div>
                                        <p className={"mb-0 main_text_color"}>{reviewsItem.text}</p>
                                    </div>
                                ))}
                                {employeeReviews.reviews.pageCount > 1 ?
                                    <ReviewsPaginationComponent
                                        currentPage={currentPage}
                                        itemsPerPage={itemsPerPage}
                                        totalItems={totalItems}
                                        onPageChange={handlePageChange}
                                    />
                                : null}
                                <div className='col-lg-4 mx-auto mt-4 pt-3'>
                                    <Link to={`/company/${companyId}/branch-menu-list/${branchId}/masters/${masterId}`}
                                          className='w-100 btn btn-outline-primary rounded-1 fw-normal'>
                                        <i className='bi bi-chevron-left'></i>
                                        {translate('masterListPage.aboutMaster')}
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
                : <Spinner/>}
        </>
    )
}

export default MasterReviews