import ReservationInfoWrapper from "../../../UI/ReservationInfoWrapper/ReservationInfoWrapper";
import CurrentMaster from "../../BranchItem/BranchMenuList/FilledMaster/CurrentMaster/CurrentMaster";
import CurrentService from "../../BranchItem/BranchMenuList/FilledService/СurrentService/CurrentService";
import CurrentTime from "../../BranchItem/BranchMenuList/FilledTime/CurrentTime/CurrentTime";
import CurrentBranch from "./CurrentBranch/CurrentBranch";
import branchPhoto from './CurrentBranch/servicePhoto.svg'
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
const ReservationInfo = ({errorsListIsEmpty}) => {

    const {t: translate} = useTranslation();
    const currentServiceInfo = useSelector(state => state.serviceReducer);
    const currentEmployeeInfo = useSelector(state => state.employeeReducer);
    const currentBranchInfo = useSelector(state => state.branchReducer);
    const currentDateTimeInfo = useSelector(state => state.dateReducer);
    const errorTranslatePath = 'reservationPage.reservationForm.formErrors';

    return (
        <>
            {errorsListIsEmpty ?
                <div className={"col-xl-12 mb-4"}>
                    <div className={"px-3 py-3 mx-auto booking-error h-100 rounded text-danger fs-7 d-flex border-danger icon_to_block align-items-center"}>
                        <i className="bi bi-exclamation-circle me-2"></i>
                        {translate(`${errorTranslatePath}.bookingFormError`)}.
                    </div>
                </div>
                :
                null
            }
            <div className="reservation-detail row ">
                {currentServiceInfo.serviceName ?
                    <ReservationInfoWrapper>
                        <CurrentService
                            serviceName={currentServiceInfo.serviceName}
                            servicePrice={currentServiceInfo.servicePrice}
                            serviceTime={currentServiceInfo.serviceTime}
                            currencyCode={currentServiceInfo.currencyCode}
                            stylesForReservation={'yes'}
                        />
                    </ReservationInfoWrapper>
                    :''}
                {currentEmployeeInfo.employeeName ?
                    <ReservationInfoWrapper>
                           <CurrentMaster
                               masterPhoto={currentEmployeeInfo.employeePhoto}
                               masterName={currentEmployeeInfo.employeeName}
                               masterPosition={currentEmployeeInfo.employeePosition}/>
                    </ReservationInfoWrapper>
                    :''}
                {currentBranchInfo.branchName ?
                    <ReservationInfoWrapper>
                        <CurrentBranch
                            photo={branchPhoto}
                            name={currentBranchInfo.branchName}
                            address={currentBranchInfo.branchAddress}/>
                    </ReservationInfoWrapper>
                    :''}
                {currentDateTimeInfo.dateIsEntered ?
                    <ReservationInfoWrapper>
                        <CurrentTime/>
                    </ReservationInfoWrapper>
                    :''}
            </div>
        </>
    )
}

export default ReservationInfo