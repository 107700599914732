import {Link, useNavigate} from "react-router-dom";
import ContentWrapper from "../../UI/ContentWrapper/ContentWrapper";
import {useDispatch} from "react-redux";
import {setBranch} from "../../store/branchReducer";
import {useTranslation} from "react-i18next";

const BranchItem = ({branchAddress, branchName, branchCity, branchId, companyId, workingTime, closestBookingDate, closestBookingTimes, timeZone}) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {t : translate} = useTranslation();

   const convertTimeSpan = (timeSpan) => {
     return timeSpan.substring(0, 5);
   }

   const pickBranchHandler = () => {
       dispatch(setBranch({
           branchName : branchName,
           branchAddress: branchAddress,
           branchCity : branchCity,
           timeZone : timeZone
       }));
       navigate(`/company/${companyId}/branch-menu-list/${branchId}`);
   }

    return (
        <ContentWrapper>
                <h5 className="fw-bold mb-1 main_text_color branch-name">{branchName}, {branchCity}</h5>
                <div className="street_time d-flex justify-content-between mb-3">
                    <p className="text-secondary mb-0 fs-18 w-75">{branchAddress}</p>
                    <p className="text-secondary mb-0 fs-18 w-50 text-end">{workingTime ? workingTime : 'Робочі години не вказані'}</p>
                </div>
                <div className="session d-flex justify-content-between mb-3">
                    <p className="mb-0 text-secondary fs-18">{translate('branchPage.nearTime')}:</p>
                    <p className="mb-0 fs-18">
                        {closestBookingDate ? closestBookingDate : `${translate('branchPage.dateIsNotFound')}`}, {closestBookingTimes?.length ?
                        convertTimeSpan(closestBookingTimes[0]?.start) :
                        `${translate('branchPage.timeIsNotFound')}`}
                    </p>
                </div>
                <div className="btn-wrapper row">
                    <div className="col-12 col-lg-6 mb-3">
                        <Link
                            to={'/company/' + companyId + '/about-branch/' + branchId}
                            className="btn btn-outline-primary w-100 fs-7 rounded-1">
                            {translate('branchPage.aboutBranch')}
                        </Link>
                    </div>
                    <div className="col-12 col-lg-6">
                        <button
                            onClick={pickBranchHandler}
                            className="btn btn-primary w-100 fw-bold fs-7  rounded">
                            {translate('branchPage.choice')}
                        </button>
                    </div>
                </div>
        </ContentWrapper>
    )
}

export default BranchItem