import confirmedImg from "./confirmedImage.svg"
import './ConfirmedReservation.scss'
import ConfirmedInfo from "./ConfirmedInfo/ConfirmedInfo";
import {useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {resetFormState} from "../../store/formReducer";
import {resetBranchState} from "../../store/branchReducer";
import {resetDateState} from "../../store/dateReducer";
import {resetServiceState} from "../../store/serviceReducer";
import {resetEmployeeState} from "../../store/employeeReducer";
import {useDispatch} from "react-redux";
import {useEffect} from "react";
import {NavigationComponent} from "../../components/NavigationComponent/NavigationComponent";

const ConfirmedReservation = () => {

    const {t: translate} = useTranslation();
    const {companyId} = useParams();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(resetFormState());
        dispatch(resetBranchState());
        dispatch(resetDateState());
        dispatch(resetServiceState());
        dispatch(resetEmployeeState());
    })

    return (
        <>
            <NavigationComponent
                navLink={`/company/${companyId}`}
                linkText={translate('toMainPage')}
            />
            <div className="confirm">
                <div className="container mb-3">
                    <div
                        className="confirmed-success py-3 px-4 col-lg-8 mx-auto d-flex flex-column flex-md-row justify-content-center align-items-center rounded">
                        <div className="image-wrapper-confirm me-0 me-md-5 mb-3 mb-md-0">
                            <img src={confirmedImg} alt=""/>
                        </div>
                        <div className="col-xl-7">
                            <div className="d-flex flex-column text-center text-md-start">
                                <h3 className="text-light">
                                    {translate('confirmedInfo.confirmReservation')}
                                </h3>
                                <p className="confirmed-text-color">
                                    {translate('confirmedInfo.lookConfirmedInfo')}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <ConfirmedInfo/>
            </div>
        </>
    )
}

export default ConfirmedReservation