const languageConvert = (currentLanguage) => {
    const lowCase = currentLanguage.toLowerCase();
    switch (lowCase) {
        case 'ua' :
            return 'uk'
        case 'ru' :
            return 'ru'
        case 'en' :
            return 'en'
        case 'cs' :
            return 'cs'
        default :
            return 'en'
    }
}

export default languageConvert;