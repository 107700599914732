import './Reviews.scss'
import img from '../../img/reviews/review-img.png'
import ReviewModal from "./ReviewModal";
import {useNavigate, useParams} from "react-router-dom";
import {useQuery} from "@apollo/client";
import {BOOKING_REVIEWS_INFO, IS_ANY_REVIEW_PER_BOOKING} from "../../apollo/Queries";
import {apolloClientPosBranch} from "../../apollo/ApolloClients";
import Spinner from "../../components/Spinner/Spinner";
import React, {useState} from "react";
import {convertDate, timeToShow} from "../../utils/workingWithDateTime/formatDateTime";
import {useTranslation} from "react-i18next";
import {NavigationComponent} from "../../components/NavigationComponent/NavigationComponent";

const Reviews = () => {

    const {t: translate} = useTranslation();
    const [, setLoading] = useState(true);
    const {companyId, uuid} = useParams();
    const navigate = useNavigate();
    const {data: bookingData} = useQuery(BOOKING_REVIEWS_INFO,
        {
            client: apolloClientPosBranch, variables: {uuid: uuid}, onCompleted: () => {
                setLoading(false)
            }
        });

    useQuery(IS_ANY_REVIEW_PER_BOOKING, {
        variables : {uuid},
        onCompleted : (data) => {
            if (data?.isAnyReviewPerBooking) {
                navigate(`/company/${companyId}/reviews/${uuid}/review-is-left`)
            }
        },
        onError : (error) => {
            console.log(error);
        }
    })

    return (
        <>
            <NavigationComponent customPosition={'justify-content-end'}/>
            {bookingData && bookingData.booking ?
                <div className="container mt-5">
                    <div className='col-lg-9 mx-auto row'>
                        <div className='col-lg-6'>
                            <div className='bg-primary rounded text-center mb-4 p-4 text-white'>
                                <img className='mb-5' src={img} alt='review'/>

                                <h2 className='fs-4'>{translate('leftReviewsPage.opinion')}!</h2>
                                <p className='col-8 mx-auto'>{translate('leftReviewsPage.leftReview')}!</p>
                            </div>
                            <div className='bg-white rounded p-4 mb-4 mb-lg-0'>
                                <h5 className='mb-4'>{translate('confirmedInfo.reservationDetails')}</h5>
                                <div className='d-flex justify-content-between fs-6'>
                                    <p className='text-info'>{translate('confirmedInfo.master')}</p>
                                    <p>{bookingData.booking.employee.name} {bookingData.booking.employee.surname}</p>
                                </div>
                                <div className='d-flex justify-content-between fs-6'>
                                    <p className='text-info'>{translate('confirmedInfo.service')}</p>
                                    <p>{bookingData.booking.service.name}</p>
                                </div>
                                <div className='d-flex justify-content-between fs-6'>
                                    <p className='text-info'>{translate('confirmedInfo.dateAndTime')}</p>
                                    <p>{timeToShow(bookingData.booking.time)}, {convertDate(new Date(bookingData.booking.date))}</p>
                                </div>
                                <div className='d-flex justify-content-between fs-6'>
                                    <p className='text-info'>{translate('confirmedInfo.branch')}</p>
                                    <div className="d-flex ps-4 justify-content-end">
                                    <p className=''>{bookingData.booking.branch.name}, м.{bookingData.booking.branch.city.name}</p>
                                    </div>
                                </div>
                                <div className='d-flex justify-content-between fs-6'>
                                    <p className='mb-0 text-info'>{translate('confirmedInfo.address')}</p>
                                    <div className="d-flex ps-4 justify-content-end">
                                    <p className='mb-0'>{bookingData.booking.branch.address}</p>
                                </div>
                                </div>
                            </div>
                        </div>
                        <ReviewModal clientName={bookingData.booking.client.name} uuid={uuid}/>
                    </div>
                </div>
                :
                <Spinner/>}
        </>
    )
}

export default Reviews