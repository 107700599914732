const defaultState = {
    branchName: null,
    branchAddress: null,
    branchCity: null,
    timeZone : null
};

const SET_BRANCH = 'SET_BRANCH';
const RESET_STATE = 'RESET_STATE';

export const branchReducer = (state = defaultState, action) => {
    switch (action.type) {
        case SET_BRANCH :
            localStorage.setItem('branchData', JSON.stringify(action.payload));
            return {
                ...state,
                branchName: action.payload.branchName,
                branchAddress: action.payload.branchAddress,
                branchCity: action.payload.branchCity,
                timeZone : action.payload.timeZone
            }
        case RESET_STATE :
            localStorage.setItem('branchData', JSON.stringify(defaultState));
            return defaultState;
        default : return state;
    }
}

export const setBranch = (payload) => ({type: SET_BRANCH, payload});
export const resetBranchState = () => ({
    type: RESET_STATE,
});