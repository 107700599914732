const defaultState = {
    employeeId: null,
    employeeName : null,
    employeePosition: null,
    employeePhoto : null,
    employeeSurname : null
}

const SET_EMPLOYEE = 'SET_EMPLOYEE';
const RESET_STATE = 'RESET_STATE';

export const employeeReducer = (state = defaultState, action) => {
    switch (action.type) {
        case SET_EMPLOYEE :
            localStorage.setItem('employeeData', JSON.stringify(action.payload));
            return {...state,
                employeeId: action.payload.employeeId,
                employeeName: action.payload.employeeName,
                employeePosition: action.payload.employeePosition,
                employeePhoto: action.payload.employeePhoto,
                employeeSurname : action.payload.employeeSurname
            }
        case RESET_STATE :
            localStorage.setItem('employeeData', JSON.stringify(defaultState));
            return defaultState
        default :return state
    }
}

export const setEmployee = (payload) => ({type : SET_EMPLOYEE, payload});
export const resetEmployeeState = () => ({
    type: RESET_STATE,
});