export const retrieveCachedData = (store, reducerActions) => {
    Object.entries(reducerActions).forEach(([reducerKey, actionCreator]) => {
        const cachedData = localStorage.getItem(reducerKey);
        if (cachedData) {
            const parsedData = JSON.parse(cachedData);
            store.dispatch(actionCreator(parsedData));
        }
    });
};

export const checkDate = () => {
    const dateFromStorage = localStorage.getItem('dateData');
    const parsedData = JSON.parse(dateFromStorage);
    if (parsedData?.currentDate) {
        const picketDate = new Date(parsedData.currentDate);
        const today = new Date();
        return picketDate.getTime() >= today.getTime();
    }
}