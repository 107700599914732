import './ReservationDetailsItem.scss'

const ReservationDetailsItem = ({title, desc, addressRef, marginClass}) => {


    return (
        <div className={marginClass + " reservation-details-item d-flex justify-content-between position-relative"}>
            <p className="text-secondary fs-7 details-title d-flex mb-0 pe-2 bg-white position-relative">{title}</p>
            <div className="position-absolute top-50 translate-middle-y w-100 test"></div>
            {addressRef
                ?
                <p className='main_text_color fs-7 mb-0 ps-2 bg-white position-relative d-flex align-items-center'>
                    {desc}
                    <i className="ms-2 bi bi-map"></i>
                </p>
                :
                <p className='main_text_color fs-7 mb-0 ps-2 bg-white position-relative'>{desc}</p>}
        </div>
    )
}

export default ReservationDetailsItem;