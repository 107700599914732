import serviceLogo from "../../img/serviceLogo.svg";
import {useSelector} from "react-redux";
import timeRangeConvert from "../../../../../utils/workingWithDateTime/timeRangeConvert";
import priceRangeConvert from "../../../../../utils/priceRangeConvert";
import {useTranslation} from "react-i18next";

const CurrentService = ({serviceName, servicePrice, serviceTime, currencyCode, stylesForReservation}) => {
    const serviceNameLength = serviceName.split('').length > 20;

    const divPositionClasses = `text-secondary mb-0 icon_to_block d-flex flex-column ${(stylesForReservation || serviceNameLength) ? 'flex-column align-items-start' : 'flex-md-row'}`;

    const currentEmployer = useSelector(state => state.employeeReducer.employeeId);
    const currentService = useSelector(state => state.serviceReducer);
    const {t: translate} = useTranslation();
    return (
        <div className="timme-info d-flex align-items-center w-100">
            <div className="image-wrapper me-4 master-photo__wrapper rounded-circle">
                <img src={serviceLogo} className="d-block" alt=""/>
            </div>
            <div className="info w-100">
                <p className="text-secondary fs-18 mb-0">{translate('branchMenuPage.service')}</p>
                <div
                    className={divPositionClasses}>
                    <span className={`branch-name fw-bold main_text_color ${(!stylesForReservation && !serviceNameLength) ? 'w-max-50' : ''}`}>{serviceName}</span>
                    <div className={'d-flex align-items-center w-md-100 fs-18'}>
                        <i className={`main_text_color bi bi-dot mx-3 d-none ${(!stylesForReservation && !serviceNameLength) ? 'd-md-block' : ''}`}></i>
                        <i className="bi bi-tag me-1 me-md-2"></i>
                        <span>{currentEmployer ? priceRangeConvert(servicePrice) : priceRangeConvert(currentService.servicePriceRange)} {currencyCode}</span>
                        <i className="main_text_color bi bi-dot mx-1 mx-md-3"></i>
                        <i className="bi bi-clock-history me-2"></i>
                        <span>{currentEmployer ? timeRangeConvert(serviceTime) : timeRangeConvert(currentService.serviceTimeRange)}</span>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default CurrentService