import './BranchMenuItem.scss'
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

const BranchMenuItem = ({textContent, currentImg, currentUrl, btnText}) => {
    const {t: translate} = useTranslation();
    return (
        <div className={"container"}>
            <div className={"col-lg-8 mx-auto"}>
                <div className="branch-menu-item text-decoration-none p-4 rounded bg-white mb-3 ">
                   <div className="row align-items-center">
                       <div className="col-8 ps-lg-0">
                           <div className="d-flex align-items-center">
                               <div className={"master-photo__wrapper rounded-circle"}>
                                   <img className="d-block" src={currentImg} alt="logo"/>
                               </div>
                               <p className="ms-4 fs-18 mb-0 main_text_color d-block d-md-none">{textContent}</p>
                               <h5 className="ms-4 mb-0 main_text_color d-none d-md-block">{textContent}</h5>
                           </div>
                       </div>
                       <div className="col-4 px-sm-2 ps-0">
                           <Link to={currentUrl} className='btn btn-primary w-100 fw-bold fs-7  rounded d-none d-sm-block'>{btnText}</Link>
                           <Link to={currentUrl} className='btn btn-primary w-100 fw-bold fs-7  rounded d-sm-none'>{translate('branchPage.choice')}</Link>
                       </div>
                   </div>
                </div>
            </div>
        </div>
    )
}

export default BranchMenuItem