import {DateTime} from "luxon";

const convertDateByTimezone = (currentTimeZone) => {
    const currentDate = DateTime.now().setZone(currentTimeZone);
    return new Date(
        currentDate.c.year,
        currentDate.c.month - 1,
        currentDate.c.day,
        currentDate.c.hour,
        currentDate.c.minute,
        currentDate.c.second)
}

export default convertDateByTimezone;