const defaultState = {
    userName: '',
    userSurname : '',
    patronymic: '',
    userPhone : '',
    userEmail: '',
    userComment: '',
    notification: '1',
    isNeedNotification : true,
    isNeedClientRecall : true
}

const SET_USER_INFO = 'SET_USER_INFO';
const RESET_STATE = 'RESET_STATE';

export const formReducer = (state = defaultState, action) => {
    switch (action.type) {
        case SET_USER_INFO :
            return {
                ...state,
                ...action.payload,
            }
        case RESET_STATE : return defaultState;
        default : return state;
    }
}

export const setUserInfo = (payload) => ({type : SET_USER_INFO, payload});
export const resetFormState = () => ({
    type: RESET_STATE,
});