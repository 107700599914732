import CurrentTime from "./CurrentTime/CurrentTime";
import {useDispatch} from "react-redux";
import {setDate} from "../../../../store/dateReducer";
import {setBookingValidate} from "../../../../store/bookingValidateReducer";

const FilledTime = ({errorBookingValidate}) => {

    const dispatch = useDispatch()
    const delPicketDate = () => {
        dispatch(setBookingValidate({validate : true}))
        const clearDate = {
            currentDate: new Date(),
            dateIsEntered: false
        }
        dispatch(setDate(clearDate))
    }

    return (
        <div className={"container"}>
            <div className={`filled-wrapper container 
              py-3 px-4 col-lg-8 rounded bg-white mb-3 
              d-flex justify-content-between align-items-center ${errorBookingValidate ? '' : ' errorBooking'}`}>
                <CurrentTime/>
                <button
                    onClick={delPicketDate}
                    className="btn icon_to_block px-0 py-0 ms-2 fs-4">
                    <i className="bi bi-x-circle text-gray"></i>
                </button>
            </div>
        </div>
    )
}

export default FilledTime