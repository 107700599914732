import FreeTimeComponent from "../../../../components/FreeTimeComponent/FreeTimeComponent";

const MasterFreeTime = ({
                            masterDate,
                            closestBookingDateTimes,
                            employeeId,
                            closestDate
                        }) => {
    const uniqueId = `accordion-${employeeId}`;
    return (
        <div className="master-free-time">
            <div className="accordion" id={uniqueId}>
                <div className="accordion-item">
                    <h2 className="accordion-header" id={`heading-${uniqueId}`}>
                        <button
                            className="accordion-button p-0 main_text_color collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={`#collapse-${uniqueId}`}
                            aria-expanded="false"
                            aria-controls={`collapse-${uniqueId}`}
                        >
                            {masterDate}
                        </button>
                    </h2>
                    <div
                        id={`collapse-${uniqueId}`}
                        className="accordion-collapse collapse"
                        aria-labelledby={`heading-${uniqueId}`}
                        data-bs-parent={`#${uniqueId}`}
                    >
                        <div className="accordion-body px-0 pt-3 pb-0">
                            <FreeTimeComponent
                                employeeId={employeeId}
                                intervals={closestBookingDateTimes}
                                closestDate={closestDate}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MasterFreeTime