import {useQuery} from "@apollo/client";
import {bookingValidate} from "../../../../apollo/Queries";
import {convertDateTimeForRequest} from "../../../../utils/workingWithDateTime/convertDateTimeForRequest";
import './ValidateBooking.scss'
import {useTranslation} from "react-i18next";
import Spinner from "../../../../components/Spinner/Spinner";
import {useState} from "react";
import {useDispatch} from "react-redux";
import {setBookingValidate} from "../../../../store/bookingValidateReducer";



const ValidateBooking = ({employerId, serviceId, pickedDate, branchId}) => {
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(true);
    const {t : translate} = useTranslation();
    const {data} = useQuery(bookingValidate, {
        variables: {
            dateTime: convertDateTimeForRequest(pickedDate),
            serviceId: serviceId,
            employeeId: employerId,
            branchId: branchId
        },
        onCompleted: (data) => {
            dispatch(setBookingValidate({validate : data?.validateBooking}))
            setLoading(false);
        },
        onError: (error) => {
            setLoading(false);
            dispatch(setBookingValidate({validate : false}))
        }
    });


    return (
        <div className={"container"}>
            {(data && data?.validateBooking) ?
                <>
                    {loading ? <Spinner/> : null}
                </>
                :
                <div className={"px-3 col-lg-8 py-3 mx-auto booking-error rounded text-danger fs-7 d-flex border-danger icon_to_block align-items-center"}>
                    {loading ? <Spinner/> : null}
                    <i className="bi bi-exclamation-circle me-2"></i>
                    {translate('bookingValidateError')}.
                </div>
            }
        </div>
    )
}

export default ValidateBooking