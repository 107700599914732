import {LanguageSelectComponent} from "../LanguageSelectComponent/LanguageSelectComponent";
import {Link} from "react-router-dom";

export const NavigationComponent = ({textNav, navLink, linkText, customPosition}) => {
    return (
        <>
            <div className={"bg-white py-3 mb-5"}>
                <div
                    className={`container px-4 px-lg-0 position-relative d-flex align-items-center ${customPosition ? customPosition : 'justify-content-between'}`}>
                    {textNav && <p className="mb-0 main_text_color fs-18 fw-normal">{textNav}</p>}
                    {navLink && <Link to={`${navLink}`}
                                      className={"d-block text-decoration-none main_text_color fs-18 fw-normal "}>
                        <i className="bi bi-chevron-left me-4"></i>
                        {linkText}
                    </Link>}
                    <LanguageSelectComponent/>
                </div>
            </div>
        </>
    )
}