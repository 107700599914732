import logoTicket from "../../img/logo/logo-ticket.svg";
import React from "react";


const AdsTicket = () => {
    return (
<div className="ticket-ads px-lg-4 px-3 h-100 ">
    <div className="d-flex align-items-center justify-content-between h-100 w-100">
        <div className="d-lg-flex">
            <div className="me-4 d-flex align-items-center">
                <img loading='lazy' className="ads-logo" src={logoTicket} alt="Me-Ticket Logo"/>
            </div>
            <div className="pe-2 pe-lg-0">
                <p className="mb-0 text-dark fs-7"><b>Upgrade Your Tickets</b> with QR Code for Easy Check-in</p>
                <p className="mb-0 fs-7 text-info d-lg-block d-none"><small>ME-Ticket is a platform for selling venue tickets. Create an event, generate<br></br> tickets with QR codes and enjoy sell-outs.</small></p>
            </div>
        </div>
        <div className="d-flex col-5 col-lg-5 justify-content-end">
            <a target="_blank" rel="noreferrer"  href="https://me-ticket.com/for-organizers?utm_source=me-booking&utm_medium=banner&utm_campaign=me-qr-generator-code" className="btn btn-ticket text-white me-lg-3 px-lg-4 rounded-1 d-lg-none w-100">Create Event</a>
            <a target="_blank" rel="noreferrer"  href="https://me-ticket.com/for-organizers?utm_source=me-booking&utm_medium=banner&utm_campaign=me-qr-generator-code" className="btn btn-ticket text-white me-lg-3 px-lg-4 rounded-1 d-lg-block d-none">Create Event</a>
            <a target="_blank" rel="noreferrer"  href="https://me-qr.com/qr-code-generator" className="d-lg-block d-none btn btn-outline-ticket fw-normal px-4 rounded-1">Generate QR for Ticket</a>
        </div>
    </div>
</div>
    )
}

export default AdsTicket;
