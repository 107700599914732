const defaultState = {
    currentDate: null,
    dateIsEntered : false,
    timeIsPickedIn : null,
    pickedTime : null
}

const SET_DATE = 'SET_DATE';
const RESET_STATE = 'RESET_STATE';

export const dateReducer = (state = defaultState, action) => {
    switch (action.type) {
        case SET_DATE :
            localStorage.setItem('dateData', JSON.stringify(action.payload));
            return {...state,
                ...action.payload,
            }
        case RESET_STATE :
            localStorage.setItem('dateData', JSON.stringify(defaultState));
            return defaultState
        default :return state
    }
}

export const setDate = (payload) => ({type: SET_DATE, payload});
export const resetDateState = () => ({
    type: RESET_STATE,
});