import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import {setEmployee} from "../../store/employeeReducer";
import {setService} from "../../store/serviceReducer";
import {setDate} from "../../store/dateReducer";


const PickMasterComponent = ({
                                 employeeId,
                                 masterName,
                                 masterPosition,
                                 masterPhoto,
                                 employeeServiceRelation,
                                 masterSurname,
                                 fullSizeBtn
                             }) => {
    const {t: translate} = useTranslation();
    const {companyId, branchId} = useParams();
    const currentService = useSelector(state => state.serviceReducer.serviceId);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const dateStageInfo = useSelector(state => state.dateReducer);
    const pickEmployeeHandler = () => {
        const currentEmployee = {
            employeeId: employeeId,
            employeeName: masterName,
            employeePosition: masterPosition,
            employeePhoto: masterPhoto,
            employeeSurname: masterSurname
        }

        if (currentService) {
            const timeAndPriceByEmployee = {
                servicePrice: employeeServiceRelation.price,
                serviceTime: employeeServiceRelation.duration,
            }
            dispatch(setService(timeAndPriceByEmployee));
        }


        if (dateStageInfo.timeIsPickedIn === employeeId) {
            const dateToUpdate = {
                currentDate: dateStageInfo.pickedTime,
                dateIsEntered: true
            }
            dispatch(setDate(dateToUpdate));
        }

        dispatch(setEmployee(currentEmployee))
        navigate(`/company/${companyId}/branch-menu-list/${branchId}`)
    }
    return (
        <button
            onClick={pickEmployeeHandler}
            className={`btn btn-primary fw-bold fs-7 rounded ${fullSizeBtn ? fullSizeBtn : ''}`}>
            {dateStageInfo.timeIsPickedIn === employeeId && !dateStageInfo.dateIsEntered ?
                translate('masterListPage.setSpecialistAndDateTime')
                :
                translate('masterListPage.setMaster')}
        </button>
    )
}

export default PickMasterComponent;