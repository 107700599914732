import '../BranchItem/BranchItem'
import BranchItem from "../BranchItem/BranchItem";
import {useQuery} from "@apollo/client";
import {COMPANY_BRANCHES} from "../../apollo/Queries";
import {useParams} from "react-router-dom";
import {apolloClientPos} from "../../apollo/ApolloClients";
import {useEffect, useState} from "react";
import Spinner from "../../components/Spinner/Spinner";
import {useDispatch, useSelector} from "react-redux";
import {resetBranchState} from "../../store/branchReducer";
import {resetEmployeeState} from "../../store/employeeReducer";
import {resetServiceState} from "../../store/serviceReducer";
import {resetDateState} from "../../store/dateReducer";
import {resetFormState} from "../../store/formReducer";
import PageNotFound from "../../components/ErrorComponents/PageNotFound/PageNotFound";
import {useTranslation} from "react-i18next";
import {resetBookingValidate, setBookingValidate} from "../../store/bookingValidateReducer";
import {NavigationComponent} from "../../components/NavigationComponent/NavigationComponent";

const BranchList = () => {
    const {t: translate} = useTranslation();
    const {companyId} = useParams();
    const dispatch = useDispatch();
    const isBookingValid = useSelector(state => state.bookingValidateReducer.validate);
    const [loading, setLoading] = useState(true);
    const {data: companyBranchesData} = useQuery(COMPANY_BRANCHES(companyId), {
        client: apolloClientPos,
        onCompleted: () => {
            setLoading(false);
        }, onError: (error) => {
            console.log(error);
            setLoading(false);
        }
    });

    useEffect(() => {
        dispatch(resetBranchState());
        dispatch(resetEmployeeState());
        dispatch(resetServiceState());
        dispatch(resetDateState());
        dispatch(resetFormState());
        dispatch(resetBookingValidate());
        if (!isBookingValid) {
            dispatch(setBookingValidate({validate: true}))
        }
    }, [dispatch, isBookingValid]);

    return (
        <>
            {(companyBranchesData && companyBranchesData.companyBranches) ?
                <>
                    <NavigationComponent textNav={translate('branchPage.setBranch')}/>
                    {
                        companyBranchesData.companyBranches.map((branch) => (
                            <BranchItem
                                key={branch.id}
                                branchName={branch.name}
                                branchAddress={branch.address}
                                branchCity={branch.city.name}
                                branchId={branch.id}
                                companyId={companyId}
                                workingTime={branch.workSchedule}
                                closestBookingDate={branch.closestBookingDate}
                                closestBookingTimes={branch.closestBookingTimes}
                                timeZone={branch.timeZone}/>
                        ))
                    }
                </>
                : loading ? <Spinner/> : <PageNotFound/>}
        </>
    )
}

export default BranchList