export const accordionOpenPosition = (hours, min, sec, currentAccordionPosition, timeByTimeZone) => {
    const timeToCheck = timeByTimeZone;
    timeToCheck.setHours(hours);
    timeToCheck.setMinutes(min);
    timeToCheck.setSeconds(sec);
    const endTime = timeByTimeZone;
    switch (currentAccordionPosition) {
        case 'morning' : {
            return timeToCheck.getTime() >= timeByTimeZone
        }
        case 'day' : {
            endTime.setHours(17);
            endTime.setMinutes(45);
            endTime.setSeconds(0);
            return  (timeToCheck.getTime() <= timeByTimeZone) && (timeByTimeZone <= endTime.getTime())

        }
        case 'night' : {
            timeToCheck.setHours(17);
            timeToCheck.setMinutes(45);
            timeToCheck.setSeconds(0);
            endTime.setHours(hours);
            endTime.setMinutes(min);
            endTime.setSeconds(sec);
            return  (timeToCheck.getTime() <= timeByTimeZone) && (timeByTimeZone <= endTime.getTime())
        }
        default : {
            break
        }
    }
}