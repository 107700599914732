import {useDispatch, useSelector} from "react-redux";
import {setDate} from "../../store/dateReducer";
import {useTranslation} from "react-i18next";
import convertDateByTimezone from "../../utils/workingWithDateTime/convertDateByTimezone";
import {useEffect} from "react";
const FreeTimeComponent = ({intervals, employeeId, closestDate}) => {

    const {t : translate} = useTranslation();
    const picketDate = useSelector(state => state.dateReducer.currentDate);
    const timeZone = useSelector(state => state.branchReducer.timeZone);
    const pickedTime = useSelector(state => state.dateReducer.pickedTime);
    const dispatch = useDispatch();
    let currentDateToSet = picketDate ? new Date(picketDate) : closestDate ? new Date(closestDate) : convertDateByTimezone(timeZone);

    useEffect(() => {
        if (!pickedTime) {
            const btnList = document.querySelectorAll('.time-btn');
            btnList.forEach((btn) => {
                btn.classList.remove('active');
            })
        }
    }, [pickedTime]);

    const pickTimeHandler = (event) => {
        const btnClassList = event.target.classList;
        if (btnClassList.contains('active')) {
            btnClassList.remove('active');
            dispatch(setDate({pickedTime: null, dateIsEntered: false, timeIsPickedIn : null}));
        } else {
            const btnList = document.querySelectorAll('.time-btn');
            btnList.forEach((btn) => {
                btn.classList.remove('active');
            })
            btnClassList.add('active');
            dispatch(setDate({pickedTime: new Date(event.target.dataset.value), dateIsEntered: false, timeIsPickedIn : employeeId}));
        }
    };


    const buttons = [];

    intervals?.forEach(({start, end}, i) => {
        const currentDate = convertDateByTimezone(timeZone);
        let startDate = new Date(currentDateToSet.getFullYear(), currentDateToSet.getMonth(), currentDateToSet.getDate(), start.split(':')[0], start.split(':')[1], start.split(':')[2]);
        let endDate = new Date(currentDateToSet.getFullYear(), currentDateToSet.getMonth(), currentDateToSet.getDate(), end.split(':')[0], end.split(':')[1], end.split(':')[2]);


        const currentMinutes = currentDate.getMinutes();
        const currentSeconds = currentDate.getSeconds();

        if (startDate < currentDate) {
            const diffMinutes = Math.ceil((currentMinutes + currentSeconds / 60) / 15) * 15;
            startDate = new Date(currentDateToSet.getFullYear(), currentDateToSet.getMonth(), currentDateToSet.getDate(), currentDate.getHours(), diffMinutes);
        }

        const diff = (endDate - startDate) / 1000 / 60;
        const intervalCount = Math.floor(diff / 15);

        for (let j = 0; j <= intervalCount; j++) {
            const date = new Date(startDate.getTime() + j * 15 * 60 * 1000);
            const timeLabel = date.toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'});
            buttons.push(<button
                key={`${i}-${j}`}
                onClick={pickTimeHandler}
                data-value={date}
                className="btn w-100 border text-center py-2 mb-2 fs-7 bg-white time-btn fw-normal">
                {timeLabel}
            </button>);
        }
    });

    return (
        <div className="work-time row px-2">
            {(buttons.length !== 0) ?
                buttons.map((btn, index) => (
                    <div key={index} className="col-lg-2 col-4 px-1">
                        {btn}
                    </div>
                )) :
                <p className="text-secondary text-center mb-0">
                    {translate('masterListPage.notFoundFreeTime')}
                </p>}
        </div>
    )
}

export default FreeTimeComponent