import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'bootstrap/dist/js/bootstrap.js';
import {BrowserRouter} from 'react-router-dom'
import reportWebVitals from './reportWebVitals';
import './index.scss';
import App from './App';
import {apolloClientPos, apolloClientPosBranch} from "./apollo/ApolloClients";
import {ApolloProvider} from "@apollo/client";
import {Provider} from "react-redux";
import {store} from "./store";
import './i18n';

const root = ReactDOM.createRoot(document.getElementById('root'));


root.render(
    <ApolloProvider client={apolloClientPos}>
        <ApolloProvider client={apolloClientPosBranch}>
            <Provider store={store}>
                <BrowserRouter>
                    <React.Suspense fallback={'loading'}>
                        <App />
                    </React.Suspense>
                </BrowserRouter>
            </Provider>
        </ApolloProvider>
    </ApolloProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();