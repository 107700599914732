const DateConvertToShow = (currentDate, currentLanguage) => {
    let days;
    if (currentLanguage) {
        if (currentLanguage.includes('en')) {
            days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
        } else if (currentLanguage.includes('ru')) {
            days = ["Воскресенье", "Понедельник", "Вторник", "Среда", "Четверг", "Пятница", "Суббота"];
        } else if (currentLanguage.includes('ua')) {
            days = ["Неділя", "Понеділок", "Вівторок", "Середа", "Четвер", "П'ятниця", "Субота"];
        } else {
            days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
        }
        return `${currentDate.getDate().toString().padStart(2, '0')}.${(currentDate.getMonth() + 1).toString().padStart(2, '0')}.${currentDate.getFullYear()},
    ${days[currentDate.getDay()]}`
    }
}

export default DateConvertToShow