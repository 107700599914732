import {convertTime} from "./convertTime";

const timeRangeConvert = (timeRange) => {
    const timeRangeSplit = timeRange?.split(',');
    const convertedTimeArr = [];
    [...timeRangeSplit].forEach((timeSpan) => {
        convertedTimeArr.push(convertTime(timeSpan))
    });
    return  convertedTimeArr.join(' - ');
}

export default timeRangeConvert;