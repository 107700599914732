import './ReviewsPaginationComponent.scss';

const ReviewsPaginationComponent = ({ currentPage, itemsPerPage, totalItems, onPageChange}) => {
    const totalPages = Math.ceil(totalItems / itemsPerPage);
    const pageNumbers = [];

    let startPage = 1;
    let endPage = Math.ceil(totalItems / itemsPerPage);

    if (endPage > 3) {
        if (currentPage <= 2) {
            endPage = 3;
        } else if (currentPage >= endPage - 1) {
            startPage = endPage - 2;
        } else {
            startPage = currentPage - 1;
            endPage = currentPage + 1;
        }
    }

    for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
    }

    const calcItemPaginationHandler = (itemPerPage, currentPage, totalItems) => {
        const startIndex = (currentPage - 1) * itemsPerPage + 1;
        let endIndex = currentPage * itemsPerPage;

        if (currentPage === Math.ceil(totalItems / itemsPerPage)) {
            endIndex = totalItems;
        }

        return `${startIndex} - ${endIndex} з ${totalItems}`;
    }


    return (
        <div className={"position-relative"}>
            <ul className="pagination justify-content-center align-items-center mb-0">
                <li className={`icon_to_block me-3`}>
                    <button className={`border-0 bg-white d-flex align-items-center ${currentPage !== 1 ? 'nav-icon' : ''}`}
                            disabled={currentPage === 1}
                            onClick={() => onPageChange(currentPage - 1)}>
                        <i className="bi bi-chevron-double-left fs-7"></i>
                    </button>
                </li>

                {pageNumbers.map((page) => (
                    <li key={page} className={`${page !== totalPages ? 'me-1' : ''}`}>
                        <button className={`fs-7 border-0 px-2 page-btn rounded ${currentPage === page ? 'bg-primary text-white' : ''}`}
                                onClick={() => onPageChange(page)}>
                            {page}
                        </button>
                    </li>
                ))}

                {((currentPage +1) < totalPages) ?
                    <li className={"d-flex align-items-center ms-3 icon_to_block"}>
                        <i className="bi bi-three-dots fs-7"></i>
                    </li>
                    : null}

                <li className={`icon_to_block ms-3 d-flex align-items-center`}>
                    <button className={`border-0 bg-white ${currentPage !== totalPages ? 'nav-icon' : ''}`}
                            disabled={currentPage === totalPages}
                            onClick={() => onPageChange(currentPage + 1)}>
                        <i className="bi bi-chevron-double-right fs-7"></i>
                    </button>
                </li>
            </ul>
            <div className={"position-absolute top-50 end-0 translate-middle-y"}>
                <p className={"mb-0 text-secondary fs-7"}>{calcItemPaginationHandler(itemsPerPage, currentPage, totalItems)}</p>
            </div>
        </div>
    );
}

export default ReviewsPaginationComponent;